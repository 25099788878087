// Copyright 2023 Descript, Inc
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { invariant, ErrorCategory } from '@descript/errors';

import { useOnOff } from './useOnOff';
import { useThrottleWithRaf } from './useThrottleWithRaf';
import { ReactNull } from './ReactNull';
import { useEventCallback } from './useEventCallback';

export interface Point {
    x: number;
    y: number;
}

export interface StartData extends Point {
    maxX: number;
    maxY: number;
}

export const PointUtils = {
    delta: (a: Point, b: Point): Point => {
        return { x: b.x - a.x, y: b.y - a.y };
    },

    origin: { x: 0, y: 0 } as Readonly<Point>,
};

export type UsePointerMoveTriggerEvents = React.PointerEvent | PointerEvent;

export function getPointForPointerMoveEvent(event: React.PointerEvent | PointerEvent): Point {
    return { x: Math.ceil(event.clientX), y: Math.ceil(event.clientY) };
}

function getElement(className: string) {
    // eslint-disable-next-line @descript-eslint/no-force-reflow
    return document.querySelector(className) as HTMLDivElement;
}

export function getWindowPosition(
    windowSelector: string,
    constraintSelector: string,
): StartData {
    // eslint-disable-next-line @descript-eslint/no-force-reflow
    const windowBounds = getElement(windowSelector).getBoundingClientRect();
    // eslint-disable-next-line @descript-eslint/no-force-reflow
    const constraintsBounds = getElement(constraintSelector).getBoundingClientRect();

    return {
        x: windowBounds.left - constraintsBounds.left,
        y: windowBounds.top - constraintsBounds.top,
        maxX: Math.max(0, constraintsBounds.width - windowBounds.width),
        maxY: Math.max(0, constraintsBounds.height - windowBounds.height),
    };
}

function getPointerMoveData(
    start: Point,
    lastPoint: Point,
    event: PointerEvent,
): PointerMoveCallbackInfo {
    const current = getPointForPointerMoveEvent(event);
    const delta = PointUtils.delta(start, current);
    // Since we're throttling we have to calculate the movement ourselves
    const movement = PointUtils.delta(lastPoint, current);

    return {
        delta,
        movement,
        currentPosition: current,
    };
}

export interface PointerMoveCallbackInfo {
    delta: Point;
    movement: Point;
    currentPosition: Point;
}

export type PointerMoveOnMoveEvent = PointerEvent;

export type UsePointerMoveOptions = {
    /**
     * Called when the move interaction starts.
     * @returns false to prevent the move interaction from starting.
     */
    onMoveStart?: (event: React.PointerEvent) => void | boolean;
    /**
     * This is called when you have a `buffer` and the buffer has not been exceeded.
     * This is called as the pointer up event.
     */
    onMoveCancel?: (event: React.PointerEvent) => void;
    onMove: (info: PointerMoveCallbackInfo, event: PointerEvent) => void;
    onMoveEnd?: (info: PointerMoveCallbackInfo, event: PointerEvent) => void;
} & (
    | {
          /**
           * The number of pixels the user must move before the move interaction starts.
           */
          buffer?: never;
          /** The direction the buffer should use before starting the interaction */
          orientation?: never;
      }
    | {
          /**
           * The number of pixels the user must move before the move interaction starts.
           */
          buffer: number;
          /** The direction the buffer should use before starting the interaction */
          orientation?: 'horizontal' | 'vertical';
      }
);

export interface UsePointerMoveReturn {
    isMoving: boolean;
    pointerMoveProps: React.HTMLAttributes<Element>;
}

const style = { touchAction: 'none' };

export function usePointerMove({
    onMoveStart,
    onMove,
    onMoveEnd,
    onMoveCancel,
    buffer = 0,
    orientation,
}: UsePointerMoveOptions): UsePointerMoveReturn {
    const [isMoving, isMovingOn, isMovingOff] = useOnOff();
    const lastMovePoint = useRef<Point | undefined>(undefined);
    const handlers = useRef<UsePointerMoveOptions | null>(ReactNull);
    const cleanUpListeners = useRef<(() => void) | null>(ReactNull);

    // Set up a function that will be called at most once per frame
    const [onUpdate, cancelUpdate] = useThrottleWithRaf({
        update: useCallback((start: Point, event: PointerEvent) => {
            const { currentPosition, delta, movement } = getPointerMoveData(
                start,
                lastMovePoint.current || start,
                event,
            );

            lastMovePoint.current = currentPosition;
            handlers.current?.onMove({ delta, movement, currentPosition }, event);
        }, []),
    });

    // Keep up to date with the latest handlers
    useEffect(() => {
        handlers.current = { onMoveStart, onMove, onMoveEnd };
    }, [onMoveStart, onMove, onMoveEnd]);

    const onPointerDown = useEventCallback((event: React.PointerEvent) => {
        let start: Point | null = ReactNull;

        const handleMove = (pointerEvent: PointerEvent) => {
            if (!start) {
                return;
            }

            if (!isMoving) {
                isMovingOn();
            }

            onUpdate(start, pointerEvent);
        };

        const handleMoveEnd = (pointerEvent: PointerEvent | FocusEvent) => {
            cancelUpdate();

            if (!start) {
                onMoveCancel?.(event);
                return;
            }

            const moveData = getPointerMoveData(
                start,
                lastMovePoint.current || start,
                pointerEvent as PointerEvent,
            );

            start = ReactNull;
            lastMovePoint.current = undefined;
            cleanUpListeners.current?.();
            handlers.current?.onMoveEnd?.(moveData, pointerEvent as PointerEvent);
            isMovingOff();
        };

        const handleMoveStart = (pointerEvent: React.PointerEvent) => {
            cancelUpdate();
            start = getPointForPointerMoveEvent(pointerEvent);
            const shouldAttachHandlers = handlers.current?.onMoveStart?.(event);

            if (shouldAttachHandlers === false) {
                return;
            }

            cleanUpListeners.current?.();

            window.addEventListener('pointermove', handleMove);
            window.addEventListener('pointerup', handleMoveEnd);
            window.addEventListener('blur', handleMoveEnd);

            cleanUpListeners.current = () => {
                window.removeEventListener('pointermove', handleMove);
                window.removeEventListener('pointerup', handleMoveEnd);
                window.removeEventListener('blur', handleMoveEnd);
            };
        };

        if (buffer) {
            event.persist();
            const moveBufferStart = getPointForPointerMoveEvent(event);

            const stopWaitingForBuffer = () => {
                window.removeEventListener('pointermove', waitForBuffer);
            };

            const waitForBuffer = (moveEvent: PointerEvent) => {
                const moveData = getPointerMoveData(
                    moveBufferStart,
                    moveBufferStart,
                    moveEvent,
                );
                const metBufferX = Math.abs(moveData.delta.x) >= buffer;
                const metBufferY = Math.abs(moveData.delta.y) >= buffer;
                const hasMovedPastBuffer = orientation
                    ? orientation === 'horizontal'
                        ? metBufferX
                        : metBufferY
                    : metBufferY || metBufferX;

                if (!hasMovedPastBuffer) {
                    return;
                }

                stopWaitingForBuffer();
                handleMoveStart(event);
                window.removeEventListener('pointerup', onCancel);
            };

            const onCancel = () => {
                stopWaitingForBuffer();
                onMoveCancel?.(event);
            };

            window.addEventListener('pointermove', waitForBuffer);
            window.addEventListener('pointerup', onCancel, { once: true });
        } else {
            handleMoveStart(event);
        }
    });

    return {
        isMoving: isMoving,
        pointerMoveProps: useMemo(
            () => ({
                onPointerDown,
                style,
                draggable: true,
                onDragStart: (e: React.DragEvent) => {
                    // If the event bubbled up from a child element that is draggable, don't prevent the drag
                    if (
                        e.target instanceof HTMLElement &&
                        e.target.closest('[draggable]') !== e.currentTarget
                    ) {
                        return;
                    }

                    e.preventDefault();
                    e.dataTransfer.dropEffect = 'none';
                },
            }),
            [onPointerDown],
        ),
    };
}

interface UseMoveItemHandlers<T> {
    onMoveStart?: (event: React.PointerEvent, item: T) => void | boolean;
    onMove: (info: PointerMoveCallbackInfo, event: PointerEvent, item: T) => void;
    onMoveEnd?: (info: PointerMoveCallbackInfo, event: PointerEvent, item: T) => void;
}

export interface UseMoveItemReturn<T> {
    isMoving: boolean;
    moveItemProps: {
        onPointerDown: (event: React.PointerEvent, currentItem: T) => void;
    };
    item: T | undefined;
}

export function useMoveItem<T>({
    onMoveStart,
    onMove,
    onMoveEnd,
}: UseMoveItemHandlers<T>): UseMoveItemReturn<T> {
    // a ref so the callback can always get the latest value
    const itemRef = useRef<T | undefined>(undefined);
    // a state so the component can re-render when the item changes
    const [item, itemSet] = useState<T | undefined>(undefined);
    const move = usePointerMove({
        onMoveStart: useEventCallback((event) => {
            if (!onMoveStart) {
                return;
            }

            invariant(
                itemRef.current,
                'useMoveItem: item is undefined',
                ErrorCategory.AppArchitecture,
            );
            return onMoveStart?.(event, itemRef.current);
        }),
        onMove: useEventCallback((info, event) => {
            invariant(
                itemRef.current,
                'useMoveItem: item is undefined',
                ErrorCategory.AppArchitecture,
            );
            onMove(info, event, itemRef.current);
        }),
        onMoveEnd: useEventCallback((data, event) => {
            invariant(
                itemRef.current,
                'useMoveItem: item is undefined',
                ErrorCategory.AppArchitecture,
            );
            onMoveEnd?.(data, event, itemRef.current);
            itemRef.current = undefined;
            itemSet(undefined);
        }),
    });

    const onPointerDown = useEventCallback((event: React.PointerEvent, currentItem: T) => {
        itemRef.current = currentItem;
        itemSet(currentItem);
        move.pointerMoveProps.onPointerDown?.(event);
    });

    return {
        isMoving: move.isMoving,
        moveItemProps: { ...move.pointerMoveProps, onPointerDown },
        item: item,
    };
}
