// Copyright 2024 Descript, Inc
export const DEFAULT_ASPECT_RATIO = 16 / 9;
export const SQUARE_ASPECT_RATIO = 1;

export const SIDEBAR_WIDTH_CLOSED = '69';
export const SIDEBAR_WIDTH = '302';
export const MOBILE_SIDEBAR_WIDTH = '300';
export const SPEAKER_PARTICIPANT_BAR_WIDTH = 192;

export const CAMERA_DISABLED_STORAGE_KEY = 'daily-camera-enabled';
export const MICROPHONE_DISABLED_STORAGE_KEY = 'daily-microphone-enabled';
export const CAMERA_DEVICE_ID_STORAGE_KEY = 'daily-camera-device-id';
export const MICROPHONE_DEVICE_ID_STORAGE_KEY = 'daily-microphone-device-id';
