// Copyright 2023 Descript, Inc

import {
    AudioMimeTypes,
    KnownErrors,
    KnownWarnings,
    MediaConfig,
    MimeTypeToFileExtension,
    RecordingEvent,
    RecordingEventContext,
    VIDEO_RES,
    VideoMimeTypes,
    VideoResLabel,
    WebRecorderSegmentMetadata,
    WebRecorderTimesMS,
    WebRecordingSession,
    FPSAnalytics,
    FPSHistogram,
    Resolution,
    ResolutionReport,
    BitrateAnalytics,
    AudioTrackInfo,
    AudioAnalytics,
    AudioMetrics,
} from '@descript/recorder-base';

import { DebugSettings, Microseconds } from '@descript/descript-core';
import PQueue from 'p-queue';
import { DemuxMKV, Segment, TrackType } from '@descript/mkv-utils';
import { Timer } from '../helpers/timer';
import { v4 as uuid } from 'uuid';
import { DemuxMP4 } from '@descript/mp4box-utils';
import {
    createCanvasMediaStreamFromMediaStream,
    writeVideoTrackToCanvas,
} from '../utils/canvasUtils';
import { DescriptError, ErrorCategory, invariant } from '@descript/errors';
import { addPaddedZeros, downloadBlob } from '../utils/downloadBlob';
import { RecorderAnalytics } from '../utils/recorderAnalytics';
import { getVideoBitRate } from '../utils/getVideoBitRate';
// would do this in a global.d.ts file, but since we import source code directly into other
// packages, that file wouldn't get picked up
declare global {
    interface MediaTrackConstraints {
        /**
         * This is an Electron-specific API https://www.electronjs.org/docs/latest/api/desktop-capturer
         */
        mandatory?: {
            chromeMediaSource: 'desktop';
            chromeMediaSourceId?: string;
        };
    }
}

type InitParams = {
    config: MediaConfig;
    mediaStream: MediaStream;
    sourceMediaStream?: MediaStream;
    recorder: MediaRecorder;
    fileExtension: string;
    constraints?: MediaStreamConstraints;

    canvas?: HTMLCanvasElement;
    canvasSourceTrack?: MediaStreamTrack;
    killCanvasWriter?: () => void;

    audioContext?: AudioContext;
    audioDestination?: MediaStreamAudioDestinationNode;
    audioDestinationSource?: MediaStreamAudioSourceNode;
};

const mediaStreamHasAudio = (mediaStream: MediaStream) =>
    mediaStream.getAudioTracks().length > 0;
const mediaStreamHasVideo = (mediaStream: MediaStream) =>
    mediaStream.getVideoTracks().length > 0;

export class WebRecorder {
    // ---------------------- PRIVATE STATIC PROPERTIES ----------------------
    private static _recorders: Map<string, WebRecorder> = new Map();
    private static _requestedResolution: (typeof VIDEO_RES)[VideoResLabel] = VIDEO_RES['4k'];
    private static _recorderFactory = new PQueue({ concurrency: 1 });
    private static _losslessAudioAllowed = false;
    private static _autoGainControlEnabled = true;

    // ---------------------- PRIVATE INSTANCE PROPERTIES ----------------------
    private _operationQueue = new PQueue({ concurrency: 1 });
    private _id = uuid();
    private _config: MediaConfig;
    private _constraints: MediaStreamConstraints | undefined;
    private _mediaStream: MediaStream;
    private _sourceMediaStream: MediaStream | undefined;
    private _recorder: MediaRecorder;
    private _fileExtension: string;
    private _recordingSessionID: string | undefined;
    private _recordingStartTimecode: number | undefined;
    private _recordingStartTimestamp: number | undefined;
    private _initSegment: Segment | undefined;
    private _terminated = false;
    private _demuxMKV = new DemuxMKV();
    private _demuxMP4 = new DemuxMP4();
    private _transcriber: import('@descript/web-recorder-transcriber').Transcriber | undefined;
    private _liveTranscribe = false;
    private _canvas: HTMLCanvasElement | undefined;
    private _canvasSourceTrack: MediaStreamTrack | undefined;
    private _killCanvasWriter: (() => void) | undefined;
    private _audioContext: AudioContext | undefined;
    private _audioDestination: MediaStreamAudioDestinationNode | undefined;
    private _audioDestinationSource: MediaStreamAudioSourceNode | undefined;

    // Analytics properties
    private _analytics: RecorderAnalytics;
    private _configuredFPS: number | undefined;
    private _configuredResolution: Resolution | undefined;
    private _configuredBitrate: number | undefined; // in kbps
    private _configuredAudio: AudioTrackInfo | undefined;

    // ---------------------- PUBLIC STATIC PROPERTIES ----------------------
    public static onRecorderEvent: (ev: RecordingEvent) => void;

    // ---------------------- PUBLIC INSTANCE PROPERTIES ----------------------
    get isRecording() {
        return this._recorder?.state === 'recording';
    }
    get id() {
        return this._id;
    }
    get config() {
        return this._config;
    }
    get constraints() {
        return this._constraints;
    }
    get terminated() {
        return this._terminated;
    }
    set terminated(value: boolean) {
        this._terminated = value;
    }
    get mediaStream() {
        return this._mediaStream;
    }
    get recordingSessionID() {
        return this._recordingSessionID;
    }
    get requestedResolution() {
        return WebRecorder._requestedResolution;
    }
    get canvasResolution() {
        return this._canvas?.width && this._canvas.height
            ? { width: this._canvas.width, height: this._canvas.height }
            : undefined;
    }
    get screenShareType() {
        return this._canvasSourceTrack?.getSettings().displaySurface;
    }

    // ---------------------- PUBLIC STATIC METHODS ----------------------
    public static getRecorder(config: MediaConfig, mediaStream?: MediaStream) {
        return this._recorderFactory.add(async () => {
            config.deviceID = config.deviceID || 'default';

            if (config.mode !== 'screen') {
                await this.checkDeviceAvailability(config.deviceID, config.mode);
                if (config.audioDeviceID) {
                    await this.checkDeviceAvailability(config.audioDeviceID, 'audio');
                }
            }

            // get recorder from map if exists
            const recorder = WebRecorder._recorders.get(WebRecorder.configToKey(config));
            if (recorder) {
                WebRecorder.onRecorderEvent({
                    type: 'info',
                    recorder: recorder.config.mode,
                    context: 'recorder_get',
                    message: `Returning existing recorder ${config.mode.toUpperCase()}`,
                });
                return recorder;
            }

            // create new recorder
            const recorderInstance = await WebRecorder.new(config, mediaStream);
            WebRecorder._recorders.set(WebRecorder.configToKey(config), recorderInstance);
            WebRecorder.onRecorderEvent({
                type: 'info',
                recorder: recorderInstance.config.mode,
                context: 'recorder_get',
                message: `Created new recorder ${config.mode.toUpperCase()}`,
            });
            return recorderInstance;
        });
    }
    public static getAllRecorders(mode?: MediaConfig['mode']): WebRecorder[] {
        if (mode) {
            return Array.from(WebRecorder._recorders.values()).filter(
                (recorder) => recorder.config.mode === mode,
            );
        }
        return Array.from(WebRecorder._recorders.values());
    }
    public static async getMediaStream(config: MediaConfig) {
        const recorder = await this.getRecorder(config);
        return recorder._mediaStream;
    }
    public static async getAllMediaStreams(mode?: MediaConfig['mode']): Promise<MediaStream[]> {
        const recorders = WebRecorder.getAllRecorders(mode);
        return recorders.map((recorder) => recorder._mediaStream);
    }
    public static devicesToAVConfig(
        audioDevices?: Array<
            MediaDeviceInfo & {
                enabled?: boolean;
            }
        >,
        videoDevices?: Array<
            MediaDeviceInfo & {
                enabled?: boolean;
            }
        >,
    ) {
        const videoDevice = videoDevices?.find((device) => device.enabled);
        const audioDevice = audioDevices?.find((device) => device.enabled);
        if (videoDevice) {
            const videoConfig: MediaConfig = {
                mode: 'video',
                deviceID: videoDevice.deviceId,
                ...(audioDevice ? { audioDeviceID: audioDevice.deviceId } : {}),
            };
            return videoConfig;
        } else if (audioDevice) {
            const audioConfig: MediaConfig = {
                mode: 'audio',
                deviceID: audioDevice?.deviceId,
            };
            return audioConfig;
        }
        return undefined;
    }
    /**
     * Kill the recorder by identifier. If the recorder is currently recording, it will skip the kill unless param `force` is set to true.
     * @param identifier - Can be a MediaConfig object, a recorderId (string), or a deviceID (string).
     * @param force - If true, it will kill the recorder even if it's currently recording. USE WITH CAUTION.
     */
    public static killRecorder(
        identifier: MediaConfig | string,
        force = false,
        stopTrack = true,
    ) {
        return this._recorderFactory.add(() =>
            this.killRecorderImplementation(identifier, force, stopTrack),
        );
    }
    public static killAllRecorders(
        mode?: MediaConfig['mode'],
        force = false,
        stopTrack = true,
    ) {
        return this._recorderFactory.add(async () => {
            const recorders = WebRecorder.getAllRecorders(mode);
            await Promise.all(
                recorders.map((recorder) =>
                    WebRecorder.killRecorderImplementation(recorder.config, force, stopTrack),
                ),
            );
        });
    }
    public static setRecordingQuality(quality: VideoResLabel) {
        WebRecorder._requestedResolution = VIDEO_RES[quality];

        WebRecorder.getAllRecorders('video').forEach((recorder) => {
            const { video } = WebRecorder.buildConstraints(recorder.config);
            if (video && video !== true) {
                recorder._mediaStream
                    ?.getVideoTracks()
                    .forEach((track) => track.applyConstraints(video));
            }
        });
    }
    public static setAutoGainControl(enabled: boolean) {
        WebRecorder._autoGainControlEnabled = enabled;

        // auto gain control doesn't affect computer audio, so we only need to update audio/video recorders
        WebRecorder.getAllRecorders().forEach((recorder) => {
            if (recorder.config.mode === 'screen') {
                return;
            }

            const { audio } = WebRecorder.buildConstraints(recorder.config);
            if (audio && audio !== true) {
                recorder._mediaStream
                    ?.getAudioTracks()
                    .forEach((track) => track.applyConstraints(audio));
            }
        });
    }
    public static pauseAllRecorders(pause: boolean) {
        const recorders = WebRecorder.getAllRecorders();
        if (pause) {
            Timer.singletonTimer.pause();
        } else {
            Timer.singletonTimer.resume();
        }
        return Promise.all(recorders.map((recorder) => recorder.pause(pause)));
    }
    public static isAnyRecorderActive() {
        const recorders = WebRecorder.getAllRecorders();
        return recorders.some((recorder) => recorder.isRecording);
    }

    public static setLosslessAudioAllowed(isAllowed: boolean) {
        this._losslessAudioAllowed = isAllowed;
    }

    // ---------------------- PRIVATE STATIC METHODS ----------------------
    private static async new(config: MediaConfig, mediaStream?: MediaStream) {
        try {
            const params: Partial<InitParams> = {};

            params.config = config;

            const useRawStream = DebugSettings.getValue('web-recorder.use_raw_stream', false);

            if (config.mode === 'screen') {
                if (useRawStream) {
                    params.mediaStream =
                        mediaStream ??
                        (await this.getScreenShareMediaStream(config, 30, useRawStream))
                            .sourceMediaStream;
                } else {
                    const {
                        canvasMediaStream,
                        canvasSourceTrack,
                        canvas,
                        killCanvasWriter,
                        sourceMediaStream,
                    } = mediaStream
                        ? await createCanvasMediaStreamFromMediaStream(mediaStream, 30)
                        : await this.getScreenShareMediaStream(config, 30, useRawStream);
                    params.mediaStream = canvasMediaStream;
                    params.sourceMediaStream = sourceMediaStream;
                    params.canvasSourceTrack = canvasSourceTrack;
                    params.canvas = canvas;
                    params.killCanvasWriter = killCanvasWriter;
                }
            } else if (mediaStream) {
                params.mediaStream = mediaStream;
            } else {
                params.constraints = this.buildConstraints(config);
                params.mediaStream = await navigator.mediaDevices.getUserMedia(
                    params.constraints,
                );

                // we were seeing a bug where the recording resolution would drop to 640x480 after changing
                // the project aspect ratio settings. Applying the constraints here seems to fix. Since
                // constraints.video can be undefined, boolean, or an instance of MediaTrackConstraints,
                // we have to check that it is truthy but also not the boolean value `true`
                if (params.constraints.video && params.constraints.video !== true) {
                    await params.mediaStream
                        .getVideoTracks()[0]
                        ?.applyConstraints(params.constraints.video);
                }
            }

            if (!params.mediaStream) {
                throw new DescriptError(
                    'Unable to get media stream for recorder',
                    ErrorCategory.WebRecorder,
                );
            }

            if (params.config.mode !== 'screen' && params.config.replaceableAudio === true) {
                params.audioContext = new AudioContext();
                params.audioDestinationSource = params.audioContext.createMediaStreamSource(
                    params.mediaStream,
                );
                // we will use this destination as a permanent audio track for the recorder
                params.audioDestination = params.audioContext.createMediaStreamDestination();
                params.audioDestinationSource.connect(params.audioDestination);
                params.mediaStream
                    .getVideoTracks()
                    .forEach((track) => params.audioDestination?.stream.addTrack(track));
                params.mediaStream = params.audioDestination.stream;
            }

            if (params.config.mode !== 'screen' && params.config.replaceableVideo === true) {
                if (useRawStream) {
                    params.canvas = undefined;
                    params.canvasSourceTrack = undefined;
                    params.killCanvasWriter = undefined;
                } else {
                    const { canvasMediaStream, canvasSourceTrack, canvas, killCanvasWriter } =
                        await createCanvasMediaStreamFromMediaStream(
                            params.mediaStream,
                            30,
                            undefined,
                            false, // passing in false because replaceableVideo is true, we want to keep stream alive
                        );
                    params.mediaStream = canvasMediaStream;
                    params.canvasSourceTrack = canvasSourceTrack;
                    params.canvas = canvas;
                    params.killCanvasWriter = killCanvasWriter;
                }
            }

            const { fileExtension, recorder } = this.createRecorder(
                params.mediaStream,
                params.config,
            );

            params.fileExtension = fileExtension;
            params.recorder = recorder;

            // Make typescript happy by casting to InitParams from Partial<InitParams>
            const p: InitParams = {
                config: params.config,
                mediaStream: params.mediaStream,
                recorder: params.recorder,
                fileExtension: params.fileExtension,
                ...params,
            };
            return new WebRecorder(p);
        } catch (error) {
            WebRecorder.onRecorderEvent({
                type: 'error',
                recorder: config.mode,
                context: 'recorder_setup',
                error: error as Error,
                deviceId: config.deviceID,
                audioDeviceId: 'audioDeviceID' in config ? config.audioDeviceID : undefined,
            });
            throw error;
        }
    }
    private static async killRecorderImplementation(
        identifier: MediaConfig | string,
        force: boolean,
        stopTrack: boolean,
    ) {
        try {
            let recorder;

            if (typeof identifier !== 'string') {
                if (!identifier.deviceID) {
                    identifier.deviceID = 'default';
                }

                recorder = WebRecorder._recorders.get(WebRecorder.configToKey(identifier));
            } else {
                const recorders = WebRecorder.getAllRecorders();
                recorder = recorders.find((r) => r.id === identifier);
                if (!recorder) {
                    recorder = recorders.find((r) => {
                        if (r.config.mode === 'screen' || r.config.mode === 'audio') {
                            return r.config.deviceID === identifier;
                        }
                        return (
                            r.config.deviceID === identifier ||
                            r.config.audioDeviceID === identifier
                        );
                    });
                }
            }

            if (!recorder) {
                WebRecorder.onRecorderEvent({
                    type: 'warning',
                    recorder: 'N/A',
                    context: 'recorder_kill',
                    message: `Kill recorder was called but recorder ${typeof identifier === 'string' ? identifier : JSON.stringify(identifier)} not found. Skipping kill recorder.`,
                });
                return;
            }

            if (recorder._recorder?.state === 'recording' && !force) {
                WebRecorder.onRecorderEvent({
                    type: 'warning',
                    recorder: recorder.config.mode,
                    context: 'recorder_kill',
                    message: `Recorder ${recorder.config.mode.toUpperCase()} is recording. Skipping kill recorder.`,
                });
                return;
            }

            await recorder.stop();

            const killStream = (stream: MediaStream) => {
                stream.getTracks().forEach((track) => track.stop());
                // need to manually fire the ended event for the "onended" cleanup to run when killing the recorder, no idea why it doesn't do that automatically when calling stop() ¯\_(ツ)_/¯
                stream?.getTracks().forEach((track) => track.dispatchEvent(new Event('ended')));
            };

            if (stopTrack) {
                if (recorder._mediaStream) {
                    killStream(recorder._mediaStream);
                }
                if (recorder._sourceMediaStream) {
                    killStream(recorder._sourceMediaStream);
                }
            }

            if (recorder._killCanvasWriter) {
                recorder._killCanvasWriter();
            }

            WebRecorder._recorders.delete(WebRecorder.configToKey(recorder.config));
            WebRecorder.onRecorderEvent({
                type: 'info',
                recorder: recorder.config.mode,
                context: 'recorder_kill',
                message: `Killed recorder ${recorder.config.mode.toUpperCase()}`,
            });
        } catch (error) {
            WebRecorder.onRecorderEvent({
                type: 'error',
                recorder: 'N/A',
                context: 'recorder_kill',
                error: error as Error,
            });
            throw error;
        }
    }
    private static configToKey(config: MediaConfig) {
        if (config.mode === 'screen') {
            return `${config.mode}-${config.deviceID}`;
        }

        return `${config.mode}-${config.deviceID}${
            config.audioDeviceID ? `-${config.audioDeviceID}` : ''
        }`;
    }
    private static async checkDeviceAvailability(
        deviceID: string,
        mode?: MediaConfig['mode'],
    ): Promise<string | void> {
        if (deviceID === undefined) {
            throw new DescriptError(
                'The device that the recorder is trying to use is not defined.',
                ErrorCategory.Recording,
            );
        }
        const devices = await navigator.mediaDevices.enumerateDevices();
        const device = devices.find((d) => d.deviceId === deviceID);
        if (!device) {
            if (deviceID === 'default') {
                // final check to see if there's a device with the kind is available

                const kind =
                    mode === 'audio'
                        ? 'audioinput'
                        : mode === 'video'
                          ? 'videoinput'
                          : undefined;
                const defaultDevice = devices.find((d) => !kind || d.kind === kind);

                if (!defaultDevice) {
                    throw new DescriptError(
                        `The default device that the recorder is trying to use is not available: ${kind}`,
                        ErrorCategory.Recording,
                    );
                }
            } else {
                throw new DescriptError(
                    `The device that the recorder is trying to use is not available: ${deviceID}`,
                    ErrorCategory.Recording,
                );
            }
        }
    }
    private static createRecorder(mediaStream: MediaStream, config: MediaConfig) {
        let mimeType: VideoMimeTypes | AudioMimeTypes | undefined = undefined;

        if (config.mode === 'audio') {
            if (MediaRecorder.isTypeSupported(AudioMimeTypes.PCM)) {
                mimeType = AudioMimeTypes.PCM;
            } else if (MediaRecorder.isTypeSupported(AudioMimeTypes.M4A)) {
                mimeType = AudioMimeTypes.M4A;
            } else if (MediaRecorder.isTypeSupported(AudioMimeTypes.OPUS)) {
                mimeType = AudioMimeTypes.OPUS;
            }
        } else {
            if (
                this._losslessAudioAllowed &&
                MediaRecorder.isTypeSupported(VideoMimeTypes.AVC1_PCM)
            ) {
                mimeType = VideoMimeTypes.AVC1_PCM;
            } else if (MediaRecorder.isTypeSupported(VideoMimeTypes.AVC1)) {
                mimeType = VideoMimeTypes.AVC1;
            } else if (MediaRecorder.isTypeSupported(VideoMimeTypes.VP9_OPUS)) {
                mimeType = VideoMimeTypes.VP9_OPUS;
            } else if (MediaRecorder.isTypeSupported(VideoMimeTypes.VP8_OPUS)) {
                mimeType = VideoMimeTypes.VP8_OPUS;
            } else if (MediaRecorder.isTypeSupported(VideoMimeTypes.MP4)) {
                mimeType = VideoMimeTypes.MP4;
            } else if (MediaRecorder.isTypeSupported(VideoMimeTypes.VP8)) {
                mimeType = VideoMimeTypes.VP8;
            }
        }

        // in Firefox if we do screenshare without audio then we cannot use opus or recording fails
        if (VideoMimeTypes.VP8_OPUS === mimeType || VideoMimeTypes.VP9_OPUS === mimeType) {
            if (mediaStream.getAudioTracks().length === 0) {
                mimeType = VideoMimeTypes.VP8;
            }
        }

        if (!mimeType) {
            throw new DescriptError(
                `Unable to create recorder: No supported mime type found for ${config.mode.toUpperCase()}`,
                ErrorCategory.Recording,
            );
        }

        const fileExtension = MimeTypeToFileExtension[mimeType];
        const recorderOptions = WebRecorder.setupRecorderOptions(mediaStream);

        const recorder = new MediaRecorder(mediaStream, {
            mimeType,
            ...recorderOptions,
        });

        return {
            fileExtension,
            recorder,
        };
    }
    private static async getScreenShareMediaStream(
        config: MediaConfig,
        targetFPS = 30,
        useRawStream = false,
    ) {
        invariant(
            config.mode === 'screen',
            'getScreenShareMediaStream called in non-screen mode',
            ErrorCategory.WebRecorder,
        );

        // Keep Descript tab in focus if selecting another tab to share.
        // Experimental: https://developer.mozilla.org/en-US/docs/Web/API/CaptureController
        let controller = undefined;
        if (window.CaptureController) {
            controller = new window.CaptureController();
            controller.setFocusBehavior('no-focus-change');
        }

        const rawMediaStream = await navigator.mediaDevices.getDisplayMedia({
            controller: config.isElectron ? undefined : controller,
            video: config.isElectron ? { deviceId: config.deviceID } : true,
            // NOTE: Always show the option to select screen audio when capturing the screen on the web
            audio: config.isElectron ? !!config.includeSystemAudio : true,
            systemAudio: config.isElectron ? undefined : 'include',
            frameRate: { ideal: targetFPS, max: targetFPS, min: 10 },
        } as DisplayMediaStreamOptions);

        if (useRawStream) {
            return {
                canvasMediaStream: undefined,
                canvasSourceTrack: undefined,
                canvas: undefined,
                killCanvasWriter: undefined,
                sourceMediaStream: rawMediaStream,
            };
        }

        return await createCanvasMediaStreamFromMediaStream(
            rawMediaStream,
            targetFPS,
            config.region,
            true, // passing in true because we want to kill the canvas stream if source screenshare stream is killed
        );
    }

    // ---------------------- PUBLIC INSTANCE METHODS ----------------------
    public async start(session: WebRecordingSession) {
        this._recordingSessionID = session.sessionId;
        this._liveTranscribe = this.shouldLiveTranscribe(session);

        let recorderStartTimeStamp = 0;

        try {
            await this._operationQueue.add(async () => {
                if (this._recorder?.state === 'recording') {
                    return;
                }

                WebRecorder.onRecorderEvent({
                    type: 'info',
                    recorder: this.config.mode,
                    context: 'recorder_start',
                    message: `Starting recorder ${this.config.mode.toUpperCase()}`,
                });
                if (this.config.mode !== 'screen' && this.config.deviceID) {
                    await WebRecorder.checkDeviceAvailability(
                        this.config.deviceID,
                        this.config.mode,
                    );
                    if (this.config.audioDeviceID) {
                        await WebRecorder.checkDeviceAvailability(
                            this.config.audioDeviceID,
                            'audio',
                        );
                    }
                }
                this.resetRecorder();
                await this.waitForRecorderReady();
                if (!this._recorder) {
                    throw new DescriptError(
                        'Recorder was undefined unexpectedly.',
                        ErrorCategory.Recording,
                    );
                }

                if (
                    this.config.mode !== 'screen' &&
                    this.config.replaceableAudio !== true &&
                    this.config.replaceableVideo !== true
                ) {
                    // if the track ends prematurely WHILE RECORDING, we want to stop the recorder and send an error event
                    // only do this if replaceableVideo is not true! If track is replaceable, it's ok if it ends
                    this.mediaStream.getTracks().forEach((track) => {
                        const prevOnEnded = track.onended;
                        track.onended = async (ev) => {
                            if (prevOnEnded) {
                                prevOnEnded.bind(track)(ev);
                            }

                            if (this._recorder?.state === 'recording') {
                                WebRecorder.onRecorderEvent({
                                    type: 'error',
                                    recorder: this.config.mode,
                                    context: KnownErrors.TRACK_ENDED_PREMATURELY,
                                    error: new DescriptError(
                                        'Media track ended unexpectedly',
                                        ErrorCategory.Recording,
                                    ),
                                });
                                await this.stop();
                                await WebRecorder.killRecorder(this.id);
                            }
                        };
                    });
                }

                recorderStartTimeStamp = performance.now();
                this._recorder.start(
                    this.config.mode === 'audio'
                        ? WebRecorderTimesMS.AUDIO_CHUNK_LENGTH
                        : WebRecorderTimesMS.VIDEO_CHUNK_LENGTH,
                );

                WebRecorder.onRecorderEvent({
                    type: 'info',
                    recorder: this.config.mode,
                    context: 'recorder_start',
                    message: `Recorder ${this.config.mode.toUpperCase()} started successfully`,
                });
            });
        } catch (error) {
            await this.stop();
            WebRecorder.onRecorderEvent({
                type: 'error',
                recorder: this.config.mode,
                context: 'recorder_start',
                error: error as Error,
            });
            throw error;
        }

        // TODO: Fix live transcription for Rooms.  REC-5703
        if (process.env.IS_ROOMS) {
            return;
        }

        try {
            await this._operationQueue.add(async () => {
                if (!this._liveTranscribe) {
                    this._transcriber = undefined;
                    return;
                }

                if (!this._mediaStream) {
                    throw new DescriptError(
                        'No media stream to use for transcribing',
                        ErrorCategory.WebRecorder,
                    );
                }

                const TranscriberImport = await import('@descript/web-recorder-transcriber');

                this._transcriber = new TranscriberImport.Transcriber(
                    session,
                    this._mediaStream,
                    this.config.mode,
                    session.sessionId,
                    WebRecorder.onRecorderEvent,
                );

                await this._transcriber.start(recorderStartTimeStamp);
            });
        } catch (error) {
            WebRecorder.onRecorderEvent({
                type: 'error',
                recorder: this.config.mode,
                context: 'transcription_start',
                error: error as Error,
            });
        }
    }
    public async stop() {
        try {
            await this._operationQueue.add(async () => {
                if (!this._recorder || this._recorder.state === 'inactive') {
                    return;
                }
                WebRecorder.onRecorderEvent({
                    type: 'info',
                    recorder: this.config.mode,
                    context: 'recorder_stop',
                    message: `Stopping recorder ${this.config.mode.toUpperCase()}`,
                });
                this._demuxMKV.stop();
                this._demuxMP4.stop();
                this._recorder.stop();
                WebRecorder.onRecorderEvent({
                    type: 'info',
                    recorder: this.config.mode,
                    context: 'recorder_stop',
                    message: `Recorder ${this.config.mode.toUpperCase()} stopped successfully`,
                });
            });
        } catch (error) {
            WebRecorder.onRecorderEvent({
                type: 'error',
                recorder: this.config.mode,
                context: 'recorder_stop',
                error: error as Error,
            });
            throw error;
        }

        try {
            if (this._transcriber) {
                await this._operationQueue.add(async () => {
                    await this._transcriber?.stop();
                });
            }
        } catch (error) {
            WebRecorder.onRecorderEvent({
                type: 'error',
                recorder: this.config.mode,
                context: 'transcription_stop',
                error: error as Error,
            });
        }
    }
    public async pause(pause: boolean) {
        const state = this._recorder?.state;
        if (state === 'recording' && pause) {
            this._recorder.pause();
            await this._transcriber?.pause();
        } else if (state === 'paused' && !pause) {
            this._recorder.resume();
            await this._transcriber?.resume();
        }
    }
    public replaceVideoTrack(videoTrack: MediaStreamTrack) {
        return this._operationQueue.add(async () => {
            if (!videoTrack) {
                throw new DescriptError(
                    'Cannot replace video track without a video track',
                    ErrorCategory.Recording,
                );
            }
            if (videoTrack.readyState !== 'live') {
                WebRecorder.onRecorderEvent({
                    type: 'info',
                    recorder: this.config.mode,
                    context: 'recorder_replace_video',
                    message: `Cannot replace video track with track that is not live`,
                }); // skip if the track is not live
                return;
            }
            if (this.config.mode !== 'video') {
                throw new DescriptError(
                    'Cannot replace video track for non-video recorder',
                    ErrorCategory.Recording,
                );
            }
            if (!this._canvas) {
                throw new DescriptError(
                    'Cannot replace video track without canvas, make sure to pass in "replaceableVideo" when passing in the video config',
                    ErrorCategory.WebRecorder,
                );
            }

            const trackAlreadyLive =
                this._canvasSourceTrack &&
                this._canvasSourceTrack.readyState === 'live' &&
                this._canvasSourceTrack.getSettings().deviceId ===
                    videoTrack.getSettings().deviceId;

            if (trackAlreadyLive) {
                // skip if the track is already live
                return;
            }

            this._canvasSourceTrack = videoTrack;

            const targetFPS = videoTrack.getSettings().frameRate || 30;
            // Killing the previous writer to avoid memory leaks and slowing down if we replace the video track multiple times with the same one.
            if (this._killCanvasWriter) {
                this._killCanvasWriter();
            }
            this._killCanvasWriter = writeVideoTrackToCanvas(
                videoTrack,
                this._canvas,
                targetFPS,
            );

            WebRecorder.onRecorderEvent({
                type: 'info',
                recorder: this.config.mode,
                context: 'recorder_replace_video',
                message: `Replaced video track for recorder ${this.config.mode.toUpperCase()}`,
            });
        });
    }
    public replaceAudioTrack(audioTrack: MediaStreamTrack) {
        return this._operationQueue.add(async () => {
            if (!audioTrack) {
                throw new DescriptError(
                    'Cannot replace audio track without an audio track',
                    ErrorCategory.WebRecorder,
                );
            }
            if (!this._audioContext) {
                throw new DescriptError(
                    'Cannot replace audio track without audio context, make sure to pass in "replaceableVideo" when passing in the audio config',
                    ErrorCategory.WebRecorder,
                );
            }
            if (!this._audioDestination) {
                throw new DescriptError(
                    'Cannot replace audio track without audio destination, make sure to pass in "replaceableVideo" when passing in the audio config',
                    ErrorCategory.WebRecorder,
                );
            }

            const trackAlreadyLive = this._audioDestinationSource?.mediaStream
                .getAudioTracks()
                .find(
                    (t) =>
                        t.readyState === 'live' &&
                        t.getSettings().deviceId === audioTrack.getSettings().deviceId,
                );
            if (trackAlreadyLive) {
                // skip if the track is already live
                return;
            }

            this._audioDestinationSource?.disconnect();
            const audioSource = this._audioContext.createMediaStreamSource(
                new MediaStream([audioTrack]),
            );
            audioSource.connect(this._audioDestination);
            this._audioDestinationSource = audioSource;

            WebRecorder.onRecorderEvent({
                type: 'info',
                recorder: this.config.mode,
                context: 'recorder_replace_audio',
                message: `Replaced audio track for recorder ${this.config.mode.toUpperCase()}`,
            });
        });
    }
    private static buildConstraints(config: MediaConfig): MediaStreamConstraints {
        const idealHeight = WebRecorder._requestedResolution.height;
        const idealWidth = WebRecorder._requestedResolution.width;
        const autoGainControl = WebRecorder._autoGainControlEnabled;

        const idealAspectRatio = idealWidth / idealHeight;
        const constraints: MediaStreamConstraints = {};

        const idealFrameRate = DebugSettings.getValue<number>(
            'web-recorder.ideal_frame_rate',
            30,
            'ideal frame rate for video recording',
        );
        const forceMinFPS = DebugSettings.getValue(
            'web-recorder.force_min_fps',
            false,
            'forces min fps to equal ideal fps',
        );

        if (config.mode === 'video') {
            constraints.video = {
                deviceId: { exact: config.deviceID },
                width: {
                    ideal: idealWidth,
                },
                aspectRatio: { ideal: idealAspectRatio },
                frameRate: {
                    ideal: idealFrameRate,
                    min: forceMinFPS ? idealFrameRate : undefined,
                },
            };
            if (config.audioDeviceID) {
                constraints.audio = {
                    autoGainControl,
                    deviceId: { exact: config.audioDeviceID },
                    echoCancellation: false,
                    channelCount: 1,
                    sampleRate: 48000,
                };
            }
        } else if (config.mode === 'audio') {
            constraints.audio = {
                autoGainControl,
                deviceId: { exact: config.deviceID },
                echoCancellation: false,
                channelCount: 1,
                sampleRate: 48000,
            };
        }

        WebRecorder.onRecorderEvent({
            type: 'info',
            recorder: config.mode,
            context: 'recorder_setup',
            message: `Built constraints for recorder ${config.mode.toUpperCase()}`,
            attributes: constraints,
        });

        return constraints;
    }
    private static setupRecorderOptions(mediaStream: MediaStream) {
        const videoBitRate = getVideoBitRate(WebRecorder._bitRateForExperiment);

        let videoBitsPerSecond = videoBitRate['1080p'];
        const audioBitsPerSecond = 256_000; // 256 Kbps

        if (mediaStreamHasVideo(mediaStream)) {
            const settings = mediaStream.getVideoTracks()[0]?.getSettings();

            if (settings?.width && settings?.height) {
                if (
                    settings.width >= VIDEO_RES['4k'].width ||
                    settings.height >= VIDEO_RES['4k'].width
                ) {
                    videoBitsPerSecond = videoBitRate['4k'];
                } else if (
                    settings.width >= VIDEO_RES['1440p'].width ||
                    settings.height >= VIDEO_RES['1440p'].height
                ) {
                    videoBitsPerSecond = videoBitRate['1440p'];
                } else if (
                    settings.width <= VIDEO_RES['720p'].width &&
                    settings.height <= VIDEO_RES['720p'].height
                ) {
                    videoBitsPerSecond = videoBitRate['720p'];
                }
            }
        }
        return {
            videoBitsPerSecond,
            audioBitsPerSecond,
            videoKeyFrameIntervalDuration: WebRecorderTimesMS.VIDEO_CHUNK_LENGTH,
        };
    }

    // ---------------------- PRIVATE INSTANCE METHODS ----------------------
    /**
     *  Constructor function declared as private to ensure
     *  new instances are only created through `getRecorder` method.
     */
    private constructor(params: InitParams) {
        this._config = params.config;
        this._mediaStream = params.mediaStream;
        this._sourceMediaStream = params.sourceMediaStream;
        this._recorder = params.recorder;
        this._fileExtension = params.fileExtension;
        this._constraints = params.constraints;
        this._terminated = false;

        this._canvas = params.canvas;
        this._canvasSourceTrack = params.canvasSourceTrack;
        this._killCanvasWriter = params.killCanvasWriter;

        this._audioContext = params.audioContext;
        this._audioDestination = params.audioDestination;
        this._audioDestinationSource = params.audioDestinationSource;

        this._recorder.onstart = this.onStart.bind(this);
        this._recorder.ondataavailable = this.onDataAvailable.bind(this);
        this._recorder.onerror = this.onError.bind(this);
        this._recorder.onstop = this.onStop.bind(this);

        const analytics = this.getMediaStreamDefaultAnalytics();
        if (analytics) {
            const { configuredFPS, configuredResolution, configuredBitrate, configuredAudio } =
                analytics;
            this._configuredFPS = configuredFPS;
            this._configuredResolution = configuredResolution;
            this._configuredBitrate = configuredBitrate;
            this._configuredAudio = configuredAudio;
        }

        this._analytics = new RecorderAnalytics(
            this.config.mode,
            WebRecorder.onRecorderEvent,
            this._configuredFPS,
            this._configuredResolution,
            this._configuredBitrate,
            this._configuredAudio,
        );
    }
    private async waitForRecorderReady(): Promise<void> {
        const recorderTrackIsLive = () =>
            this._mediaStream.getTracks().some((track) => track.readyState === 'live');
        let timedOut = false;

        const timeout = setTimeout(
            () => (timedOut = !recorderTrackIsLive()),
            WebRecorderTimesMS.RECORDING_START_TIMEOUT,
        );

        while (!recorderTrackIsLive() && !timedOut) {
            await new Promise((resolve) => setTimeout(resolve, 50));
        }

        clearTimeout(timeout);
        if (timedOut) {
            throw new DescriptError(
                'Recorder timed out: Recorder track took too long to become "live".',
                ErrorCategory.WebRecorder,
            );
        }
    }
    private shouldLiveTranscribe(session: WebRecordingSession) {
        try {
            if (
                !mediaStreamHasAudio(this._mediaStream) ||
                session.transcriptionLanguage !== 'en' ||
                !session.isLiveTxEnabledByFeatureFlag
            ) {
                return false;
            }

            // prefer mic audio for live tx, use computer audio otherwise, or none if both are disabled
            const liveTranscriptionTarget: MediaConfig['mode'][] =
                session.microphoneTrackSettings.transcriptionEnabled &&
                WebRecorder.getAllRecorders().some(
                    (recorder) =>
                        mediaStreamHasAudio(recorder._mediaStream) &&
                        recorder.config.mode !== 'screen',
                )
                    ? ['video', 'audio']
                    : session.computerAudioTrackSettings.transcriptionEnabled
                      ? ['screen']
                      : [];

            return liveTranscriptionTarget.includes(this.config.mode);
        } catch (error) {
            WebRecorder.onRecorderEvent({
                type: 'error',
                recorder: this.config.mode,
                context: 'transcription_start',
                error: error as Error,
            });

            return false;
        }
    }
    private resetRecorder(): void {
        this._recordingStartTimecode = undefined;
        this._recordingStartTimestamp = undefined;
        this._initSegment = undefined;
        this._demuxMKV = new DemuxMKV();
        this._demuxMP4 = new DemuxMP4();
        this._terminated = false;
        this._analytics = new RecorderAnalytics(
            this.config.mode,
            WebRecorder.onRecorderEvent,
            this._configuredFPS,
            this._configuredResolution,
            this._configuredBitrate,
            this._configuredAudio,
        );
    }

    private async onError(
        ev: Event | Error,
        context: RecordingEventContext = 'recorder_start',
    ): Promise<void> {
        let error;
        if (ev instanceof Error) {
            error = ev;
        } else if (ev instanceof ErrorEvent) {
            error = ev.error;
        } else {
            // eslint-disable-next-line @typescript-eslint/no-base-to-string
            error = new DescriptError(ev.toString(), ErrorCategory.WebRecorder);
        }
        WebRecorder.onRecorderEvent({
            type: 'error',
            recorder: this.config.mode,
            context,
            error: error as Error,
        });
        await this.stop();
    }
    private async onDataAvailable(blobEvent: BlobEvent) {
        const blob = blobEvent.data;
        if (blob === undefined || blob.size === 0) {
            return;
        }

        try {
            await this._operationQueue.add(async () => {
                try {
                    const autoDownloadPreDemuxChunk = DebugSettings.getValue<boolean>(
                        'web-recorder.auto_download_pre_demux_chunk',
                        false,
                    );
                    if (autoDownloadPreDemuxChunk) {
                        downloadBlob(
                            blob,
                            `pre-demux-${this.config.mode}-${Date.now()}.${this._fileExtension}`,
                        );
                    }
                } catch (error) {
                    // noop if this fails
                }

                if (!this._recordingStartTimecode) {
                    // This is used for alignment, it's ms accurate. For checking time limits, use the timeSTAMP not the timeCODE
                    // TODO: Remove the timecode check after Chrome decides what to do with the timecode, right now the dev build is giving us a timecode of 0 and this check is required to keep alignment working. Also if performance.now is the solution we decide to go with, move this to the OnStart method to avoid weird edge cases!!!!
                    // TODO - but keep this check for the undefined case for Firefox
                    this._recordingStartTimecode =
                        blobEvent.timecode !== undefined && blobEvent.timecode > 1000 * 60 * 60
                            ? blobEvent.timecode
                            : performance.now();
                }

                if (
                    this._recordingStartTimestamp &&
                    Date.now() - this._recordingStartTimestamp >
                        WebRecorderTimesMS.RECORDING_TIME_LIMIT
                ) {
                    const limitError = new DescriptError(
                        'Recording time limit reached',
                        ErrorCategory.WebRecorder,
                    );
                    limitError.name = KnownWarnings.RECORDING_DURATION_LIMIT_REACHED;
                    throw limitError;
                }

                const mimeType = blob.type as VideoMimeTypes | AudioMimeTypes;
                const fileExtension = MimeTypeToFileExtension[mimeType];

                if (!fileExtension) {
                    throw new DescriptError(
                        `Unsupported mime type: ${blob.type}`,
                        ErrorCategory.WebRecorder,
                    );
                }

                if (mimeType.includes('mp4')) {
                    await this.processMP4Data(blob);
                } else {
                    await this.processMatroskaData(blob);
                }

                if (
                    this._initSegment === undefined &&
                    this._recordingStartTimestamp &&
                    Date.now() - this._recordingStartTimestamp >
                        WebRecorderTimesMS.INIT_SEGMENT_TIMEOUT
                ) {
                    const err = new DescriptError(
                        `Initial segment timeout reached for recorder ${this.config.mode.toUpperCase()}. Stopping recorder. Please try again after refreshing the page.`,
                        ErrorCategory.Recording,
                    );
                    err.name = KnownErrors.INIT_SEGMENT_TIMEOUT;
                    throw err;
                }
            });
        } catch (error) {
            await this.stop();

            if (
                (error as DescriptError)?.name ===
                KnownWarnings.RECORDING_DURATION_LIMIT_REACHED
            ) {
                WebRecorder.onRecorderEvent({
                    type: 'warning',
                    recorder: this.config.mode,
                    context: KnownWarnings.RECORDING_DURATION_LIMIT_REACHED,
                    message: `Recording time limit reached for recorder ${this.config.mode.toUpperCase()}. Stopping recorder.`,
                });
            } else {
                WebRecorder.onRecorderEvent({
                    type: 'error',
                    recorder: this.config.mode,
                    context: 'media_upload',
                    error: error as Error,
                });
            }
        }
    }
    private onStart() {
        return this._operationQueue.add(async () => {
            this._recordingStartTimestamp = Date.now();
            const videoSettings = this._recorder?.stream.getVideoTracks()[0]?.getSettings();
            const audioSettings = this._recorder?.stream.getAudioTracks()[0]?.getSettings();
            invariant(
                this.recordingSessionID,
                'recordingSessionID should be defined',
                ErrorCategory.WebRecorder,
            );
            WebRecorder.onRecorderEvent({
                type: 'start',
                metadata: {
                    recorderId: this.id,
                    recordingSessionId: this.recordingSessionID,
                    fileExtension: this._fileExtension,
                    mode: this.config.mode,
                    hasAudio: mediaStreamHasAudio(this._mediaStream),
                    hasVideo: mediaStreamHasVideo(this._mediaStream),
                    audioSettings,
                    videoSettings,
                    liveTranscribe: this._liveTranscribe,
                },
            });
        });
    }
    private onStop(ev: Event) {
        return this._operationQueue.add(async () => {
            invariant(
                this.recordingSessionID,
                'recordingSessionID should be defined',
                ErrorCategory.WebRecorder,
            );
            WebRecorder.onRecorderEvent({
                type: 'stop',
                metadata: {
                    recorderId: this.id,
                    recordingSessionId: this.recordingSessionID,
                    mode: this.config.mode,
                    terminated: this._terminated,
                },
            });
        });
    }

    private async processMatroskaData(blob: Blob) {
        try {
            const demuxedData = await this._demuxMKV.segment(blob);

            WebRecorder.onRecorderEvent({
                type: 'info',
                recorder: this.config.mode,
                context: 'matroska_decoder',
                message: `Successfully demuxed ${demuxedData.length} segments`,
                attributes: demuxedData,
            });

            // sometimes we get [] from the demuxer, so we need to check for that edge case and bail if so
            if (demuxedData.length === 0) {
                return;
            }

            if (!this._initSegment) {
                const initSegment = demuxedData.find((segment) => segment.isInit);
                if (!initSegment) {
                    throw new DescriptError(
                        'Received data without the header blob.',
                        ErrorCategory.Recording,
                    );
                }
                this._initSegment = initSegment;

                try {
                    if (initSegment.tracks) {
                        const demuxerAudioInfo = this._analytics.getAudioTrackInfo(
                            initSegment.tracks,
                        );
                        const demuxerResolution = this._analytics.getVideoTrackResolution(
                            initSegment.tracks,
                        );
                        if (demuxerResolution) {
                            this._analytics.generateResolutionReport(
                                demuxerResolution,
                                this.config.mode === 'screen' ? 'screen' : 'video',
                            );
                        }
                        if (demuxerAudioInfo) {
                            this._analytics.updateAudioMetrics('demuxer', {
                                segmentSize: 0,
                                duration: 0.001,
                                sampleRate: demuxerAudioInfo.sampleRate,
                                channelCount: demuxerAudioInfo.channelCount,
                                bitDepth: demuxerAudioInfo.bitDepth,
                            });
                        }
                    }
                } catch (error) {
                    WebRecorder.onRecorderEvent({
                        type: 'warning',
                        recorder: this.config.mode,
                        context: 'resolution_analytics',
                        message: 'Resolution tracking failed but recording continues',
                    });
                }

                let hasAudioTrack = false;
                let hasVideoTrack = false;
                if (initSegment.tracks) {
                    for (const track of initSegment.tracks.values()) {
                        if (track.type === TrackType.AUDIO) {
                            hasAudioTrack = true;
                        } else if (track.type === TrackType.VIDEO) {
                            hasVideoTrack = true;
                        }
                    }
                }

                // If we are missing expected audio or video data, we emit an error and let the
                // handlers for each app decide what to do.
                if (mediaStreamHasAudio(this._mediaStream) && !hasAudioTrack) {
                    let errMessage =
                        'Recording stopped - no audio was detected from the microphone.';
                    if (this.config.mode === 'screen') {
                        errMessage =
                            'Recording stopped - no audio was detected from your computer.';
                    }
                    const err = new DescriptError(errMessage, ErrorCategory.Recording);
                    err.name = KnownErrors.INIT_SEGMENT_NO_AUDIO;
                    WebRecorder.onRecorderEvent({
                        type: 'error',
                        recorder: this.config.mode,
                        context: 'matroska_decoder',
                        error: err,
                    });
                }
                if (mediaStreamHasVideo(this._mediaStream) && !hasVideoTrack) {
                    let errMessage =
                        'Recording stopped - no video was detected from the camera.';
                    if (this.config.mode === 'screen') {
                        errMessage =
                            'Recording stopped - no video was detected from your shared screen.';
                    }
                    const err = new DescriptError(errMessage, ErrorCategory.Recording);
                    err.name = KnownErrors.INIT_SEGMENT_NO_VIDEO;
                    WebRecorder.onRecorderEvent({
                        type: 'error',
                        recorder: this.config.mode,
                        context: 'matroska_decoder',
                        error: err,
                    });
                }
            }

            demuxedData
                .map((segment) => this.processDemuxedSegment(segment))
                .forEach((processResult) => {
                    WebRecorder.onRecorderEvent({
                        type: 'ondataavailable',
                        blob: processResult.blob,
                        metadata: processResult.metadata,
                    });
                });

            // Track and Calculate Analytics off the critical path
            try {
                this.processSegmentAnalytics(demuxedData, blob);
            } catch (error) {
                // Log error but continue with critical recording path
                WebRecorder.onRecorderEvent({
                    type: 'warning',
                    recorder: this.config.mode,
                    context: 'fps_analytics',
                    message: 'FPS & Bitrate tracking failed but recording continues',
                });
            }
        } catch (error) {
            WebRecorder.onRecorderEvent({
                type: 'error',
                recorder: this.config.mode,
                context: 'matroska_decoder',
                error: error as Error,
            });
        }
    }

    private processDemuxedSegment(segment: Segment): {
        metadata: WebRecorderSegmentMetadata;
        blob: Blob;
    } {
        if (segment.duration === undefined) {
            throw new DescriptError(
                `Demuxed data segment ${segment.number} did not contain duration.`,
                ErrorCategory.WebRecorder,
            );
        }
        if (segment.startTimecode === undefined) {
            throw new DescriptError(
                `Demuxed data segment ${segment.number} did not contain start timecode.`,
                ErrorCategory.WebRecorder,
            );
        }

        invariant(
            this.recordingSessionID,
            'recordingSessionID should be defined',
            ErrorCategory.WebRecorder,
        );

        try {
            const autoDownloadPostDemuxChunk = DebugSettings.getValue<boolean>(
                'web-recorder.auto_download_post_demux_chunk',
                false,
            );
            if (autoDownloadPostDemuxChunk) {
                downloadBlob(
                    segment.data,
                    `post-demux-${this.config.mode}-${addPaddedZeros(segment.number, 5)}${segment.isInit ? '-INIT-HEADER' : ''}.${this._fileExtension}`,
                );
            }
        } catch (error) {
            // noop if this fails
        }

        return {
            metadata: {
                recorderId: this.id,
                recordingSessionId: this.recordingSessionID,
                mode: this.config.mode,
                recordingStartTimecode: this._recordingStartTimecode,
                chunkNumber: segment.number,
                chunkStartOffset: (segment.startTimecode * 1000) as Microseconds,
                chunkDuration: (segment.duration * 1000) as Microseconds,
                isInit: segment.isInit, // Add isInit here
                fileExtension: this._fileExtension,
            },
            blob: segment.data,
        };
    }

    private async processMP4Data(blob: Blob) {
        try {
            const demuxedData = await this._demuxMP4.segment(blob);

            WebRecorder.onRecorderEvent({
                type: 'info',
                recorder: this.config.mode,
                context: 'mp4_decoder',
                message: `Successfully demuxed ${demuxedData.length} segments`,
                attributes: demuxedData,
            });

            if (demuxedData.length === 0) {
                return;
            }

            if (!this._initSegment) {
                const initSegment = demuxedData.find((segment) => segment.isInit);
                if (!initSegment) {
                    throw new DescriptError(
                        'Received data without the header blob.',
                        ErrorCategory.Recording,
                    );
                }
                this._initSegment = initSegment;
            }

            demuxedData
                .map((segment) => this.processDemuxedSegment(segment))
                .forEach((processResult) => {
                    const metadata = {
                        ...processResult.metadata,
                        chunkDuration: Math.floor(
                            processResult.metadata.chunkDuration * 1000,
                        ) as Microseconds,
                    };
                    WebRecorder.onRecorderEvent({
                        type: 'ondataavailable',
                        blob: processResult.blob,
                        metadata: metadata,
                    });
                });
        } catch (error) {
            WebRecorder.onRecorderEvent({
                type: 'error',
                recorder: this.config.mode,
                context: 'mp4_decoder',
                error: error as Error,
            });
        }
    }

    /**
     * Tracks and updates FPS (Frames Per Second) metrics for a given demuxer segment
     * @param segment - The media segment containing duration and frame count information
     * @returns void
     *
     * @remarks
     * This method:
     * - Skips segments with no duration, initialization segments, or segments without video
     * - Calculates FPS by dividing frame count by duration (converted to seconds)
     * - Updates the FPS histogram for either screen or video recording mode
     */
    private trackSegmentFPS(segment: Segment): void {
        if (
            segment.duration && // Only process segments with duration
            !segment.isInit && // Skip initialization segments
            mediaStreamHasVideo(this._mediaStream) // Only track FPS for video streams
        ) {
            const fps = ((segment.frameCount ?? 0) / segment.duration) * 1000;
            const demuxerFPSHistogram = this._analytics.generateFPSHistogram(fps, 'demuxer');
            if (demuxerFPSHistogram) {
                this._analytics.setFPSHistogram('demuxer', demuxerFPSHistogram);
            }
        }
    }

    /**
     * Gets the default analytics for the MediaStream, including FPS and resolution.
     *
     * We use getSettings() from the VideoTrack to access the actual applied constraints
     * that were negotiated between the browser and hardware. This is important because
     * the requested constraints may differ from what was actually possible to deliver.
     * For example, if we request 60fps but the camera only supports 30fps, getSettings()
     * will return the actual 30fps value that was applied.
     *
     * @returns Object containing configured FPS and resolution if available, undefined otherwise
     */
    private getMediaStreamDefaultAnalytics():
        | {
              configuredFPS: number | undefined;
              configuredResolution: Resolution | undefined;
              configuredBitrate: number | undefined;
              configuredAudio: AudioTrackInfo | undefined;
          }
        | undefined {
        if (this._terminated || !this._mediaStream) return;
        const mode = this.config.mode;
        const videoTrack = this._mediaStream.getVideoTracks()[0];
        const audioTrack = this._mediaStream.getAudioTracks()[0];

        let configuredFPS: number | undefined;
        let configuredResolution: Resolution | undefined;
        let configuredBitrate: number | undefined;
        let configuredAudio:
            | { sampleRate: number; channelCount: number; bitDepth: number }
            | undefined;

        if (videoTrack) {
            const settings = videoTrack.getSettings();
            if (!settings.width || !settings.height) {
                WebRecorder.onRecorderEvent({
                    type: 'warning',
                    recorder: mode,
                    context: 'resolution_analytics',
                    message: 'Could not get video track settings',
                });
                return undefined;
            }
            configuredFPS = settings.frameRate;
            const pixelTotal = settings.width * settings.height;
            configuredResolution = {
                width: settings.width ?? 0,
                height: settings.height ?? 0,
                pixelTotal,
            };
            const options = WebRecorder.setupRecorderOptions(this._mediaStream);
            configuredBitrate = Number((options.videoBitsPerSecond / 1_000).toFixed(3)); // Convert to kbps

            if (configuredFPS && configuredResolution) {
                WebRecorder.onRecorderEvent({
                    type: 'info',
                    recorder: mode,
                    context: 'bitrate_analytics',
                    message: `Configured Recorder for ${mode}, Bitrate: ${configuredBitrate / 1_000} Mbps`,
                    attributes: {
                        configuredBitrate,
                        source: 'track_settings',
                    },
                });
                WebRecorder.onRecorderEvent({
                    type: 'info',
                    recorder: mode,
                    context: 'fps_analytics',
                    message: `Configured Recorder for ${mode}, FPS: ${configuredFPS}`,
                    attributes: {
                        configuredFPS,
                        source: 'track_settings',
                    },
                });
                WebRecorder.onRecorderEvent({
                    type: 'info',
                    recorder: mode,
                    context: 'resolution_analytics',
                    message: `Configured Recorder for ${mode}, Resolution: ${configuredResolution.width}x${configuredResolution.height}`,
                    attributes: {
                        configuredResolution,
                        source: 'track_settings',
                    },
                });
            }
        }

        if (audioTrack) {
            try {
                const settings = audioTrack.getSettings?.();
                if (settings?.sampleRate && settings?.channelCount) {
                    configuredAudio = {
                        sampleRate: settings.sampleRate,
                        channelCount: settings.channelCount,
                        bitDepth: 32, // Web Audio API typically uses 32-bit float
                    };

                    WebRecorder.onRecorderEvent({
                        type: 'info',
                        recorder: mode,
                        context: 'audio_analytics',
                        message: `Configured Audio for ${mode}, Sample Rate: ${settings.sampleRate}Hz, Channels: ${settings.channelCount}`,
                        attributes: {
                            configuredAudio,
                            source: 'track_settings',
                        },
                    });
                }
            } catch (error) {
                // If getSettings() is not supported or fails, we'll just skip audio analytics
                WebRecorder.onRecorderEvent({
                    type: 'warning',
                    recorder: mode,
                    context: 'audio_analytics',
                    message: 'Could not get audio track settings',
                });
            }
        }

        return { configuredFPS, configuredResolution, configuredBitrate, configuredAudio };
    }

    /**
     * Processes analytics data from demuxed segments, calculating bitrate and FPS metrics.
     * Failures in analytics processing will not interrupt the recording.
     * @param demuxedData - Array of demuxed segments to analyze
     * @param blob - Raw blob containing the full recording data
     */
    private processSegmentAnalytics(demuxedData: Segment[], blob: Blob) {
        try {
            // Calculate total duration of the recording in seconds by:
            // 1. Summing up all segment durations (in milliseconds)
            // 2. Converting from milliseconds to seconds by dividing by 1000
            const blobDurationSec =
                demuxedData.reduce((total, segment) => total + (segment.duration || 0), 0) /
                1000;

            if (blobDurationSec > 0) {
                // Calculate overall mediastream bitrate using the entire blob
                // This gives us the "raw" bitrate before any encoding/compression
                // We pass:
                // - 'mediastream' to identify this as pre-encoded metrics
                // - blob.size as total bytes
                // - blobDurationSec as total duration
                this._analytics.updateBitrateMetrics('mediastream', blob.size, blobDurationSec);
            }

            // Calculate per-segment bitrates from the demuxed data
            // This shows us the post-encoding bitrate which may differ from mediastream
            // due to compression and encoding settings
            demuxedData.map((segment) => {
                // Only process valid data segments (not initialization segments)
                if (segment.duration && !segment.isInit && segment.data) {
                    const durationSec = segment.duration / 1000;
                    this._analytics.updateBitrateMetrics(
                        'demuxer',
                        segment.data.size,
                        durationSec,
                    );

                    this._analytics.updateAudioMetrics('demuxer', {
                        segmentSize: segment.data.size,
                        duration: durationSec,
                    });
                }
            });
        } catch (error) {
            // Log error but continue with critical recording path
            WebRecorder.onRecorderEvent({
                type: 'warning',
                recorder: this.config.mode,
                context: 'bitrate_analytics',
                message: 'BitrateAnalytics failed but recording continues',
            });
        }

        // Calculate FPS for each segment
        try {
            demuxedData.map((segment) => this.trackSegmentFPS(segment));
        } catch (error) {
            WebRecorder.onRecorderEvent({
                type: 'warning',
                recorder: this.config.mode,
                context: 'fps_analytics',
                message: 'FPS Analytics failed but recording continues',
            });
        }
    }

    /**
     * Gets FPS analytics data for the current recorder.
     * Includes statistics from both the media stream and demuxer sources if available.
     * @returns Analytics data containing mode, mediastream stats and demuxer stats, or null if no data available
     */
    public getFPSAnalytics(): FPSAnalytics | undefined {
        return this._analytics.getFPSAnalytics();
    }

    /**
     * Sets the FPS histogram for a specific source
     * @param source - The source of the FPS data ('demuxer' or 'mediastream')
     * @param histogram - The FPS histogram data to set
     */
    public setFPSHistogram(source: 'demuxer' | 'mediastream', histogram: FPSHistogram) {
        this._analytics.setFPSHistogram(source, histogram);
    }

    /**
     * Gets the resolution report for the current recorder.
     * @returns Resolution report data containing mode and resolution stats, or null if no data available
     */
    public getResolutionReport(): ResolutionReport | undefined {
        return this._analytics.getResolutionReport();
    }

    /**
     * Gets the bitrate analytics data for the current recorder.
     * @returns Analytics data containing mode, mediastream stats and demuxer stats, or null if no data available
     */
    public getBitrateAnalytics(): BitrateAnalytics | undefined {
        return this._analytics.getBitrateAnalytics();
    }

    /**
     * Gets the audio analytics data for the current recorder.
     * @returns Analytics data containing mode, audio stats, or null if no data available
     */
    public getAudioAnalytics(): AudioAnalytics | undefined {
        return this._analytics.getAudioAnalytics();
    }

    /**
     * Sets the audio metrics for the current recorder.
     * @param source - The source of the audio metrics ('demuxer' or 'mediastream')
     * @param metrics - The audio metrics data to set
     */
    public updateAudioMetrics(
        source: 'demuxer' | 'mediastream',
        metrics: Partial<AudioMetrics> & { duration: number; segmentSize: number },
    ) {
        this._analytics.updateAudioMetrics(source, metrics);
    }

    //// Experiement feature flag injection - Delete after experiment
    private static _bitRateForExperiment = 'excluded-from-experiment';
    public static setBitRateForExperiment(cohort: string) {
        WebRecorder._bitRateForExperiment = cohort;
    }
    /////
}
