// Copyright 2024 Descript, Inc
import {
    recordSpanCanceled,
    recordSpanError,
    startSpan,
    AsyncContext,
    SpanTag,
    errorCategoryContext,
} from '@descript/analytics';
import { SpanNames } from '@descript/client/src/Utilities/Tracing';
import { RecorderError } from '@descript/client/src/Storage/QuickRecordingSession';
import { VideoRecorderError } from '@descript/client/src/Recording/ErrorsAndAnalytics/errors';
import { isExpectedError } from '@descript/client/src/Recording/ErrorsAndAnalytics/errorHandler';
import { Attributes } from '@opentelemetry/api';
import { SpanStorage } from './spanStorage';
import { PlatformHelpers } from '@descript/descript-core';
import { trackError } from '@descript/client/src/Utilities/ErrorTracker';
import { ErrorCategory, DescriptError } from '@descript/errors';
import { RecorderContext, WebRecordingSession } from '@descript/recorder-base';

const defaultCtx = errorCategoryContext(ErrorCategory.Recording);
const sessionMap = new Map<string, AsyncContext>();
const spanMap = new Map<string, string>(); // spanId -> sessionId
const recoverySessions = new Set<string>();
let userId = 'unknown';

type SpanType = (typeof SpanNames)[keyof typeof SpanNames];
export enum SpanStatus {
    COMPLETED = 'completed',
    CANCELED = 'canceled',
    ERRORED = 'errored',
}
export type SetupErrorPhase = 'failure-to-launch' | 'configuring' | 'waiting-to-start';

export type WebRecorderSpanType =
    | typeof SpanNames.WebRecorder_Session
    | typeof SpanNames.WebRecorder_SetupInputs
    | typeof SpanNames.WebRecorder_InitRecorder
    | typeof SpanNames.WebRecorder_StartWorkflow
    | typeof SpanNames.WebRecorder_Setup
    | typeof SpanNames.WebRecorder_CreateAsset
    | typeof SpanNames.WebRecorder_SignalTrack
    | typeof SpanNames.WebRecorder_CreateArtifact
    | typeof SpanNames.WebRecorder_DataAvailableSetup
    | typeof SpanNames.WebRecorder_DataAvailableUpload
    | typeof SpanNames.WebRecorder_ReadMetadata
    | typeof SpanNames.WebRecorder_ReifyArtifact
    | typeof SpanNames.WebRecorder_CommitArtifact
    | typeof SpanNames.WebRecorder_WaitForWorkflow
    | typeof SpanNames.WebRecorder_RecoverySession
    | typeof SpanNames.WebRecorder_InstantPlayback;

export type CommonAttributeArguments = {
    recordingId?: string;
    sessionId: string;
    projectId: string;
    backendWorkflowId?: string;
    isOwner?: boolean;
};

// This is a pretty rough check right now, can probably be improved
export const CurrentRecorderContext = process.env.IS_ROOMS
    ? RecorderContext.ROOMS
    : RecorderContext.WEB;

/**
 * This function sets the common attributes for a span.
 * @function commonAttributes
 * @param {CommonAttributeArguments} args - The common attributes for the span.
 * @returns {Attributes} - The attributes for the span.
 */
const commonAttributes = ({ sessionId, projectId, isOwner }: CommonAttributeArguments) => ({
    [SpanTag.appRecorderContext]: CurrentRecorderContext,
    [SpanTag.appRecorderBrowser]: PlatformHelpers.getBrowser().toString(),
    [SpanTag.appRecorderSessionId]: sessionId,
    [SpanTag.appProjectId]: projectId,
    [SpanTag.appRoomIsOwner]: isOwner,
});

export function setUserId(id: string): void {
    userId = id;
}

export const droppedSpanTracker = {
    spanOpened: async function (spanType: SpanType, context: RecorderContext, spanId: string) {
        await SpanStorage.saveInProgressSpan(userId, spanType, context, spanId);
    },
    spanClosed: async function (spanType: SpanType, context: RecorderContext, spanId: string) {
        await SpanStorage.closeInProgressSpan(userId, spanType, context, spanId);
    },
    getInProgressSpans: async function () {
        return await SpanStorage.getInProgressSpans();
    },
};

export function trackDroppedSpan(spanType: SpanType, spanId: string) {
    const ctx = startSpan(spanType as string, undefined, defaultCtx());
    ctx.span.setAttributes({
        [SpanTag.appRecorderContext]: CurrentRecorderContext,
        [SpanTag.metricName]: spanType as string,
    });
    const error = new DescriptError('Dropped Span Detected', ErrorCategory.Recording);
    recordSpanError(ctx.span, error);
    ctx?.span.end();
    droppedSpanTracker.spanClosed(spanType, CurrentRecorderContext, spanId).catch((err) => {
        trackError(err, 'dropped-span-close-failed', { category: ErrorCategory.Recording });
    });
}

export function setSessionSpanAttributes(
    sessionId: string,
    attributes: Attributes,
    webRecorderSession?: WebRecordingSession,
): void {
    const ctx = sessionMap.get(sessionId);

    if (ctx) {
        const initialSpanAttributes = {
            [SpanTag.appRecorderBrowser]: webRecorderSession?.environment?.browser,
            [SpanTag.appRecorderBrowserVersion]:
                webRecorderSession?.environment?.browser_version,
            [SpanTag.appRecorderOperatingSystem]: webRecorderSession?.environment?.os,
            [SpanTag.appRecorderOperatingSystemVersion]:
                webRecorderSession?.environment?.os_version,
            [SpanTag.appRecorderEquipmentCamera]:
                webRecorderSession?.equipment?.get('video')?.label,
            [SpanTag.appRecorderEquipmentMicrophone]:
                webRecorderSession?.equipment?.get('audio')?.label,
            [SpanTag.appRecorderFPSMode]: webRecorderSession?.fpsAnalytics?.mode,
            [SpanTag.appRecorderFPSConfigured]: webRecorderSession?.fpsAnalytics?.configuredFPS,
            // Mediastream metrics
            [SpanTag.appRecorderMediaStreamAvgFPS]:
                webRecorderSession?.fpsAnalytics?.mediaStream?.avgFPS,
            [SpanTag.appRecorderMediaStreamBestFPS]:
                webRecorderSession?.fpsAnalytics?.mediaStream?.bestFPS,
            [SpanTag.appRecorderMediaStreamWorstFPS]:
                webRecorderSession?.fpsAnalytics?.mediaStream?.worstFPS,
            [SpanTag.appRecorderMediaStreamTotalSamples]:
                webRecorderSession?.fpsAnalytics?.mediaStream?.totalSamples,
            [SpanTag.appRecorderMediaStreamAvgBitrate]:
                webRecorderSession?.bitrateAnalytics?.mediaStream?.avgBitrate,
            [SpanTag.appRecorderMediaStreamPeakBitrate]:
                webRecorderSession?.bitrateAnalytics?.mediaStream?.peakBitrate,
            [SpanTag.appRecorderMediaStreamMinBitrate]:
                webRecorderSession?.bitrateAnalytics?.mediaStream?.minBitrate,
            [SpanTag.appRecorderMediaStreamTotalBytes]:
                webRecorderSession?.bitrateAnalytics?.mediaStream?.totalBytes,
            [SpanTag.appRecorderMediaStreamTotalDuration]:
                webRecorderSession?.bitrateAnalytics?.mediaStream?.totalDuration,
            [SpanTag.appRecorderMediaStreamSampleCount]:
                webRecorderSession?.bitrateAnalytics?.mediaStream?.sampleCount,
            // Audio metrics (mediastream)
            [SpanTag.appRecorderAudioMediaStreamSampleRate]:
                webRecorderSession?.audioAnalytics?.mediaStream?.sampleRate,
            [SpanTag.appRecorderAudioMediaStreamChannelCount]:
                webRecorderSession?.audioAnalytics?.mediaStream?.channelCount,
            [SpanTag.appRecorderAudioMediaStreamBitDepth]:
                webRecorderSession?.audioAnalytics?.mediaStream?.bitDepth,
            [SpanTag.appRecorderAudioMediaStreamAvgBitrate]:
                webRecorderSession?.audioAnalytics?.mediaStream?.avgBitrate,
            [SpanTag.appRecorderAudioMediaStreamPeakBitrate]:
                webRecorderSession?.audioAnalytics?.mediaStream?.peakBitrate,
            [SpanTag.appRecorderAudioMediaStreamMinBitrate]:
                webRecorderSession?.audioAnalytics?.mediaStream?.minBitrate,
            [SpanTag.appRecorderAudioMediaStreamTotalBytes]:
                webRecorderSession?.audioAnalytics?.mediaStream?.totalBytes,
            [SpanTag.appRecorderAudioMediaStreamTotalDuration]:
                webRecorderSession?.audioAnalytics?.mediaStream?.totalDuration,
            [SpanTag.appRecorderAudioMediaStreamSampleCount]:
                webRecorderSession?.audioAnalytics?.mediaStream?.sampleCount,
            [SpanTag.appRecorderAudioMediaStreamSegmentSize]:
                webRecorderSession?.audioAnalytics?.mediaStream?.segmentSize,
            [SpanTag.appRecorderAudioMediaStreamDuration]:
                webRecorderSession?.audioAnalytics?.mediaStream?.duration,
            // Audio metrics
            [SpanTag.appRecorderAudioConfiguredSampleRate]:
                webRecorderSession?.audioAnalytics?.configuredAudio?.sampleRate,
            [SpanTag.appRecorderAudioConfiguredChannelCount]:
                webRecorderSession?.audioAnalytics?.configuredAudio?.channelCount,
            [SpanTag.appRecorderAudioConfiguredBitDepth]:
                webRecorderSession?.audioAnalytics?.configuredAudio?.bitDepth,
            // Audio metrics (demuxer)
            [SpanTag.appRecorderAudioDemuxerSampleRate]:
                webRecorderSession?.audioAnalytics?.demuxer?.sampleRate,
            [SpanTag.appRecorderAudioDemuxerChannelCount]:
                webRecorderSession?.audioAnalytics?.demuxer?.channelCount,
            [SpanTag.appRecorderAudioDemuxerBitDepth]:
                webRecorderSession?.audioAnalytics?.demuxer?.bitDepth,
            [SpanTag.appRecorderAudioDemuxerAvgBitrate]:
                webRecorderSession?.audioAnalytics?.demuxer?.avgBitrate,
            [SpanTag.appRecorderAudioDemuxerPeakBitrate]:
                webRecorderSession?.audioAnalytics?.demuxer?.peakBitrate,
            [SpanTag.appRecorderAudioDemuxerMinBitrate]:
                webRecorderSession?.audioAnalytics?.demuxer?.minBitrate,
            [SpanTag.appRecorderAudioDemuxerTotalBytes]:
                webRecorderSession?.audioAnalytics?.demuxer?.totalBytes,
            [SpanTag.appRecorderAudioDemuxerTotalDuration]:
                webRecorderSession?.audioAnalytics?.demuxer?.totalDuration,
            [SpanTag.appRecorderAudioDemuxerSampleCount]:
                webRecorderSession?.audioAnalytics?.demuxer?.sampleCount,
            // Resolution metrics
            [SpanTag.appRecorderResolutionConfiguredWidth]:
                webRecorderSession?.resolutionAnalytics?.configuredResolution.width,
            [SpanTag.appRecorderResolutionConfiguredHeight]:
                webRecorderSession?.resolutionAnalytics?.configuredResolution.height,
            [SpanTag.appRecorderResolutionConfiguredPixelTotal]:
                webRecorderSession?.resolutionAnalytics?.configuredResolution.pixelTotal,
            [SpanTag.appRecorderResolutionActualWidth]:
                webRecorderSession?.resolutionAnalytics?.actualResolution.width,
            [SpanTag.appRecorderResolutionActualHeight]:
                webRecorderSession?.resolutionAnalytics?.actualResolution.height,
            [SpanTag.appRecorderResolutionActualPixelTotal]:
                webRecorderSession?.resolutionAnalytics?.actualResolution.pixelTotal,
            [SpanTag.appRecorderResolutionDeltaWidth]:
                webRecorderSession?.resolutionAnalytics?.deltaResolution.width,
            [SpanTag.appRecorderResolutionDeltaHeight]:
                webRecorderSession?.resolutionAnalytics?.deltaResolution.height,
            [SpanTag.appRecorderResolutionDeltaPixelTotal]:
                webRecorderSession?.resolutionAnalytics?.deltaResolution.pixelTotal,
            // Demuxer metrics
            [SpanTag.appRecorderDemuxerAvgFPS]:
                webRecorderSession?.fpsAnalytics?.demuxer?.avgFPS,
            [SpanTag.appRecorderDemuxerBestFPS]:
                webRecorderSession?.fpsAnalytics?.demuxer?.bestFPS,
            [SpanTag.appRecorderDemuxerWorstFPS]:
                webRecorderSession?.fpsAnalytics?.demuxer?.worstFPS,
            [SpanTag.appRecorderDemuxerTotalSamples]:
                webRecorderSession?.fpsAnalytics?.demuxer?.totalSamples,
            [SpanTag.appRecorderDemuxerAvgBitrate]:
                webRecorderSession?.bitrateAnalytics?.demuxer?.avgBitrate,
            [SpanTag.appRecorderDemuxerPeakBitrate]:
                webRecorderSession?.bitrateAnalytics?.demuxer?.peakBitrate,
            [SpanTag.appRecorderDemuxerMinBitrate]:
                webRecorderSession?.bitrateAnalytics?.demuxer?.minBitrate,
            [SpanTag.appRecorderDemuxerTotalBytes]:
                webRecorderSession?.bitrateAnalytics?.demuxer?.totalBytes,
            [SpanTag.appRecorderDemuxerTotalDuration]:
                webRecorderSession?.bitrateAnalytics?.demuxer?.totalDuration,
            [SpanTag.appRecorderDemuxerSampleCount]:
                webRecorderSession?.bitrateAnalytics?.demuxer?.sampleCount,
            // Delta metrics
            [SpanTag.appRecorderFPSAvgDelta]:
                webRecorderSession?.fpsAnalytics?.deltaMetrics?.avgFPSDelta,
            [SpanTag.appRecorderFPSSampleDelta]:
                webRecorderSession?.fpsAnalytics?.deltaMetrics?.sampleCountDelta,
            [SpanTag.appRecorderFPSWorstDelta]:
                webRecorderSession?.fpsAnalytics?.deltaMetrics?.worstFPSDelta,
            [SpanTag.appRecorderFPSBestDelta]:
                webRecorderSession?.fpsAnalytics?.deltaMetrics?.bestFPSDelta,
        };
        ctx.span.setAttributes({ ...initialSpanAttributes, ...attributes });
    }
}

export function getSessionCtx(sessionId: string): AsyncContext | undefined {
    return sessionMap.get(sessionId);
}

export function startWebRecordingTrace(
    initialAttributes: CommonAttributeArguments,
    ctx?: AsyncContext,
): AsyncContext {
    const newCtx = startWebRecordingSpan(
        ctx ? SpanNames.WebRecorder_RecoverySession : SpanNames.WebRecorder_Session,
        initialAttributes.sessionId,
        ctx,
        commonAttributes(initialAttributes),
    );
    if (!sessionMap.has(initialAttributes.sessionId)) {
        sessionMap.set(initialAttributes.sessionId, newCtx);
        if (ctx) {
            recoverySessions.add(initialAttributes.sessionId);
        }
    }
    return newCtx;
}

export function endWebRecordingTrace(
    sessionId: string,
    error?: RecorderError | VideoRecorderError,
    attributes?: Attributes,
): void {
    const ctx = sessionMap.get(sessionId);
    if (ctx) {
        if (attributes) {
            ctx.span.setAttributes(attributes);
        }
        const isRecovery = recoverySessions.has(sessionId);
        endWebRecordingSpan(
            ctx,
            isRecovery ? SpanNames.WebRecorder_RecoverySession : SpanNames.WebRecorder_Session,
            error ? SpanStatus.ERRORED : SpanStatus.COMPLETED,
            undefined,
            error,
        );
        sessionMap.delete(sessionId);
        if (isRecovery) {
            recoverySessions.delete(sessionId);
        }
    }
}

/**
 * This function creates a span for the web recorder.
 * @generator
 * @function createWebRecordingSpan
 * @param {WebRecorderSpanType} spanName - The name of the span.
 * @param {string} sessionId - The session id.
 * @param {CommonAttributeArguments} basicAttributes - The basic attributes for the span.
 * @param {Attributes} [extra] - The extra attributes for the span.
 *
 * This function creates a span for the web recorder and sets the attributes for the span.
 */
export function startWebRecordingSpan(
    spanName: WebRecorderSpanType,
    sessionId: string,
    ctx?: AsyncContext,
    attributes?: Attributes | undefined,
): AsyncContext {
    const sessionSpan = sessionMap.get(sessionId);
    const spanCTX: AsyncContext = startSpan(
        spanName,
        undefined,
        ctx ? ctx : sessionSpan ? sessionSpan : defaultCtx(),
    );
    spanCTX.span.setAttributes({
        [SpanTag.metricName]: spanName,
        [SpanTag.appRecorderContext]: CurrentRecorderContext,
        [SpanTag.appRecorderSessionId]: sessionId,
        ...attributes,
    });

    const spanId = spanCTX.span.spanContext().spanId;
    droppedSpanTracker.spanOpened(spanName, CurrentRecorderContext, spanId).catch((err) => {
        trackError(err, 'dropped-span-open-failed', { category: ErrorCategory.Recording });
    });
    spanMap.set(spanId, sessionId);
    return spanCTX;
}

/**
 * This function ends a span for the web recorder.
 * @generator
 * @function endWebRecordingSpan
 * @param {AsyncContext} ctx - The context for the span.
 * @param {WebRecorderSpanType} spanName - The name of the span.
 * @param {SpanStatus} status - The status of the span.
 * @param {CommonAttributeArguments | Attributes} [attributes] - The attributes for the span.
 * @param {RecorderError | VideoRecorderError} [error] - The error for the span.
 *
 * This function ends a span for the web recorder based on the status of the span.
 */
export function endWebRecordingSpan(
    ctx: AsyncContext,
    spanName: WebRecorderSpanType,
    status: SpanStatus,
    attributes?: CommonAttributeArguments | Attributes | undefined,
    error?: RecorderError | VideoRecorderError | undefined,
): void {
    if (attributes) {
        ctx.span.setAttributes(attributes);
    }

    //TODO: Setup Web Recorder to Throw this Error in cases where it makes sense
    if (
        VideoRecorderError.isVideoRecorderError(error) &&
        error.errorObject &&
        'error_type' in error.errorObject
    ) {
        ctx.span.setAttributes({
            [SpanTag.appRecorderErrorCategory]: error.errorObject.error_type,
            [SpanTag.appRecorderErrorMessage]: error.errorObject.error_message,
            [SpanTag.appRecorderErrorExtra]:
                error.errorObject.name + ' ' + error.errorObject.description,
        });
    }

    //TODO: Setup Web Recorder to Throw this Error in cases where it makes sense
    if (error && isExpectedError(error as Error)) {
        status = SpanStatus.CANCELED;
    }

    switch (status) {
        case SpanStatus.COMPLETED:
            ctx.span.setAttribute(SpanTag.appRecorderSuccess, 1);
            break;
        case SpanStatus.CANCELED:
            recordSpanCanceled(ctx.span);
            break;
        case SpanStatus.ERRORED:
            ctx.span.setAttribute(SpanTag.appRecorderSuccess, 0);
            recordSpanError(ctx?.span, error);
            break;
        default:
            throw new DescriptError(`Unhandled status: ${status}`, ErrorCategory.Recording);
    }
    const spanId = ctx.span.spanContext().spanId;
    ctx.span.end();
    droppedSpanTracker.spanClosed(spanName, CurrentRecorderContext, spanId).catch((err) => {
        trackError(err, 'dropped-span-close-failed', { category: ErrorCategory.Recording });
    });
}
