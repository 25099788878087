// Copyright 2024 Descript, Inc

import { TrackInfo, TrackType } from '@descript/mkv-utils';
import {
    FPSHistogram,
    FPSMetrics,
    FPSAnalytics,
    RecordingEvent,
    ResolutionReport,
    Resolution,
    BitrateMetrics,
    BitrateAnalytics,
    AudioMetrics,
    AudioAnalytics,
    AudioTrackInfo,
} from '@descript/recorder-base';

export class RecorderAnalytics {
    private readonly _resolutionReport: Map<string, ResolutionReport> = new Map();
    private readonly _fpsHistograms: {
        demuxer: Map<string, FPSHistogram>;
        mediastream: Map<string, FPSHistogram>;
    } = {
        demuxer: new Map(),
        mediastream: new Map(),
    };
    private readonly _bitrateMetrics: {
        demuxer: Map<string, BitrateMetrics>;
        mediastream: Map<string, BitrateMetrics>;
    } = {
        demuxer: new Map(),
        mediastream: new Map(),
    };
    private readonly _audioMetrics: {
        demuxer: Map<string, AudioMetrics>;
        mediastream: Map<string, AudioMetrics>;
    } = {
        demuxer: new Map(),
        mediastream: new Map(),
    };

    constructor(
        private readonly _mode: 'video' | 'screen' | 'audio',
        private readonly _onRecorderEvent: (ev: RecordingEvent) => void,
        private readonly _configuredFPS: number | undefined,
        private readonly _configuredResolution: Resolution | undefined,
        private readonly _configuredBitrate?: number,
        private readonly _configuredAudio?: AudioTrackInfo,
    ) {}

    /**
     * Gets the FPS histogram for a specific source and recording mode
     */
    public getFPSHistogram(source: 'demuxer' | 'mediastream'): FPSHistogram | undefined {
        return this._fpsHistograms[source].get(this._mode);
    }

    /**
     * Sets the FPS histogram for a given source
     */
    public setFPSHistogram(source: 'demuxer' | 'mediastream', histogram: FPSHistogram) {
        this._fpsHistograms[source].set(this._mode, histogram);
    }

    /**
     * Generates or updates an FPS histogram for either demuxer or mediastream source
     */
    public generateFPSHistogram(
        fps: number,
        source: 'demuxer' | 'mediastream',
    ): FPSHistogram | undefined {
        if (this._mode === 'audio') {
            return;
        }

        let histogram = this._fpsHistograms[source].get(this._mode);
        if (!histogram) {
            histogram = {
                source,
                mode: this._mode,
                buckets: [
                    { min: 0, max: 5, count: 0 },
                    { min: 5, max: 10, count: 0 },
                    { min: 10, max: 15, count: 0 },
                    { min: 15, max: 20, count: 0 },
                    { min: 20, max: 25, count: 0 },
                    { min: 25, max: 30, count: 0 },
                    { min: 30, max: 35, count: 0 },
                    { min: 35, max: 40, count: 0 },
                    { min: 40, max: 45, count: 0 },
                    { min: 45, max: 50, count: 0 },
                    { min: 50, max: 55, count: 0 },
                    { min: 55, max: 60, count: 0 },
                    { min: 60, max: Number.MAX_SAFE_INTEGER, count: 0 },
                ],
            };
            this._fpsHistograms[source].set(this._mode, histogram);
        }

        const targetBucket = histogram.buckets.find(
            (bucket) => fps >= bucket.min && fps < bucket.max,
        );
        if (targetBucket) {
            targetBucket.count++;
        }

        const totalSamples = histogram.buckets.reduce((sum, bucket) => sum + bucket.count, 0);
        // Only update and emit events every 10 samples
        if (totalSamples % 10 === 0) {
            const metrics = this.calculateFPSMetrics(histogram);
            this._onRecorderEvent({
                type: 'info',
                recorder: this._mode,
                context: 'fps_analytics',
                message: `FPS Metrics for ${histogram.mode} on the ${histogram.source}`,
                attributes: {
                    source: histogram.source,
                    mode: histogram.mode,
                    metrics,
                },
            });
            return histogram;
        }
        return undefined;
    }

    /**
     * Gets FPS analytics data for the current recorder.
     * Includes statistics from both the media stream and demuxer sources if available.
     */
    public getFPSAnalytics(): FPSAnalytics | undefined {
        if (this._mode === 'audio') {
            return;
        }

        const mediaStreamHist = this._fpsHistograms.mediastream.get(this._mode);
        const demuxerHist = this._fpsHistograms.demuxer.get(this._mode);

        // Return undefined if no demuxer histogram exists
        if (!demuxerHist) {
            return undefined;
        }

        const demuxMetrics = this.calculateFPSMetrics(demuxerHist);

        // If mediaStream histogram exists, include delta metrics
        if (mediaStreamHist) {
            const mediaMetrics = this.calculateFPSMetrics(mediaStreamHist);
            const deltaMetrics = {
                avgFPSDelta: Math.abs(
                    Number((mediaMetrics.avgFPS - demuxMetrics.avgFPS).toFixed(2)),
                ),
                sampleCountDelta: Math.abs(
                    mediaMetrics.totalSamples - demuxMetrics.totalSamples,
                ),
                worstFPSDelta: Math.abs(mediaMetrics.worstFPS - demuxMetrics.worstFPS),
                bestFPSDelta: Math.abs(mediaMetrics.bestFPS - demuxMetrics.bestFPS),
            };
            return {
                mode: this._mode,
                mediaStream: mediaMetrics,
                demuxer: demuxMetrics,
                deltaMetrics,
                configuredFPS: this._configuredFPS ?? undefined,
            };
        }

        // Return just demuxer metrics if mediaStream histogram doesn't exist
        return {
            mode: this._mode,
            demuxer: demuxMetrics,
            configuredFPS: this._configuredFPS ?? undefined,
        };
    }

    private calculateFPSMetrics(histogram: FPSHistogram): FPSMetrics {
        const totalSamples = histogram.buckets.reduce((sum, bucket) => sum + bucket.count, 0);
        // Calculate weighted average FS
        let totalWeightedFPS = 0;
        histogram.buckets.forEach((bucket) => {
            const midpoint = (bucket.min + bucket.max) / 2;
            totalWeightedFPS += midpoint * bucket.count;
        });
        const avgFPS = totalWeightedFPS / totalSamples;
        // Find most common range
        const mostCommonBucket = histogram.buckets.reduce((prev, current) =>
            current.count > prev.count ? current : prev,
        );
        const mostCommonRange = `${mostCommonBucket.min}-${mostCommonBucket.max}`;
        const mostCommonCount = mostCommonBucket.count;
        // Find best and worst FPS (using non-empty buckets)
        const nonEmptyBuckets = histogram.buckets.filter((bucket) => bucket.count > 0);
        const bestFPS = Math.max(...nonEmptyBuckets.map((bucket) => bucket.max));
        const worstFPS = Math.min(...nonEmptyBuckets.map((bucket) => bucket.min));

        return {
            avgFPS: Number(avgFPS.toFixed(2)),
            mostCommonRange,
            mostCommonCount,
            bestFPS,
            worstFPS,
            totalSamples,
        };
    }

    /**
     * Sets the resolution report for the current recording mode
     * @param report - The resolution report containing actual and configured resolutions
     */
    public setResolutionReport(report: ResolutionReport) {
        this._resolutionReport.set(this._mode, report);
    }

    /**
     * Gets the resolution report for the current recording mode
     * @returns The resolution report if available, undefined otherwise
     */
    public getResolutionReport(): ResolutionReport | undefined {
        return this._resolutionReport.get(this._mode);
    }

    /**
     * Generates and stores a resolution report comparing actual vs configured resolutions.
     * The delta resolution is the absolute difference between the actual and configured resolutions.
     * If there is any form of delta we dont care if the value is positive or negative.
     * Also calculates the pixel total for the resolution.
     *
     * @param actualResolution - The actual resolution being used
     * @param mode - The recording mode ('video' or 'screen')
     */
    public generateResolutionReport(
        actualResolution: Resolution,
        mode: 'video' | 'screen',
    ): void {
        if (!this._configuredResolution) {
            return;
        }
        const pixelTotal = actualResolution.width * actualResolution.height;
        this._resolutionReport.set(mode, {
            actualResolution,
            configuredResolution: this._configuredResolution,
            deltaResolution: {
                width: Math.abs(actualResolution.width - this._configuredResolution.width),
                height: Math.abs(actualResolution.height - this._configuredResolution.height),
                pixelTotal: Math.abs(pixelTotal - this._configuredResolution.pixelTotal),
            },
        });
    }

    /**
     * Extracts resolution information from video track metadata.
     * Also calculates the pixel total for the resolution.
     * @param tracks - Map of track IDs to track information
     * @returns Resolution object if video track exists with dimensions, undefined otherwise
     */
    public getVideoTrackResolution(tracks: Map<number, TrackInfo>): Resolution | undefined {
        const videoTrack = Array.from(tracks.values()).find(
            (track) => track.type === TrackType.VIDEO,
        );
        if (!videoTrack?.height || !videoTrack?.width) return undefined;

        const pixelTotal = videoTrack?.width * videoTrack?.height;
        return { width: videoTrack.width, height: videoTrack.height, pixelTotal };
    }

    /**
     * Gets bitrate analytics data for the current recording mode.
     * Includes statistics from both the media stream (raw) and demuxer (encoded) sources.
     * @returns Analytics data containing mode, mediastream stats, demuxer stats and configured bitrate, or undefined if no data available
     */
    public getBitrateAnalytics(): BitrateAnalytics | undefined {
        if (this._mode === 'audio') {
            return;
        }

        const mediaStreamMetrics = this._bitrateMetrics.mediastream.get(this._mode);
        const demuxerMetrics = this._bitrateMetrics.demuxer.get(this._mode);

        if (!mediaStreamMetrics && !demuxerMetrics) {
            return undefined;
        }

        return {
            mode: this._mode,
            mediaStream: mediaStreamMetrics,
            demuxer: demuxerMetrics,
            configuredBitrate: this._configuredBitrate,
        };
    }

    /**
     * Updates bitrate metrics for a specific source with new segment data.
     * Calculates running averages and tracks min/max values.
     * Emits analytics events every 10 samples.
     * @param source - The source of the data ('demuxer' or 'mediastream')
     * @param segmentSize - Size of the current segment in bytes
     * @param duration - Duration of the segment in seconds
     */
    public updateBitrateMetrics(
        source: 'demuxer' | 'mediastream',
        segmentSize: number, // in bytes
        duration: number, // in seconds
    ): void {
        let metrics = this._bitrateMetrics[source].get(this._mode);

        if (!metrics) {
            metrics = {
                avgBitrate: 0,
                peakBitrate: 0,
                minBitrate: Infinity,
                totalBytes: 0,
                totalDuration: 0,
                sampleCount: 0,
            };
            this._bitrateMetrics[source].set(this._mode, metrics);
        }

        // Round duration to 3 decimal places to avoid floating point imprecision & errors in calculations
        const roundedDuration = Number(duration.toFixed(3));

        // Calculate bitrate in kbps:
        // 1. Multiply bytes by 8 to get bits
        // 2. Divide by duration to get bits per second
        // 3. Divide by 1000 to convert to kilobits
        // 4. Round to 3 decimal places for consistency
        const bitrate = Number(((segmentSize * 8) / roundedDuration / 1000).toFixed(3));

        // Accumulate totals using the rounded duration
        metrics.totalBytes += segmentSize;
        metrics.totalDuration = Number((metrics.totalDuration + roundedDuration).toFixed(3));
        // Increment sample count for analytics reporting
        metrics.sampleCount++;
        // Calculate average bitrate in kbps using total accumulated values:
        // (total bits) / (total seconds) / 1000 for kbps
        metrics.avgBitrate = Number(
            ((metrics.totalBytes * 8) / metrics.totalDuration / 1000).toFixed(3),
        );
        // Track highest observed bitrate, rounded to 3 decimals
        metrics.peakBitrate = Number(Math.max(metrics.peakBitrate, bitrate).toFixed(3));
        // Track lowest observed bitrate, rounded to 3 decimals
        metrics.minBitrate = Number(Math.min(metrics.minBitrate, bitrate).toFixed(3));

        // Emit analytics event every 10 samples
        if (metrics.sampleCount % 10 === 0) {
            this._onRecorderEvent({
                type: 'info',
                recorder: this._mode,
                context: 'bitrate_analytics',
                message: `Bitrate Metrics (kbps) for ${this._mode} on the ${source}`,
                attributes: {
                    source,
                    mode: this._mode,
                    metrics,
                },
            });
        }
    }

    /**
     * Gets audio analytics data for the current recorder.
     * Includes statistics from both the media stream and demuxer sources if available.
     * @returns Analytics data containing mode, mediastream stats, demuxer stats and configured audio settings, or undefined if no data available
     */
    public getAudioAnalytics(): AudioAnalytics | undefined {
        const mediaStreamMetrics = this._audioMetrics.mediastream.get(this._mode);
        const demuxerMetrics = this._audioMetrics.demuxer.get(this._mode);

        if (!mediaStreamMetrics && !demuxerMetrics) {
            return undefined;
        }

        return {
            mode: this._mode,
            mediaStream: mediaStreamMetrics,
            demuxer: demuxerMetrics,
            configuredAudio: this._configuredAudio,
        };
    }

    /**
     * Updates audio metrics for a specific source with new segment data.
     * Calculates running averages and tracks min/max values for bitrate.
     * @param source - The source of the audio data ('demuxer' or 'mediastream')
     * @param segmentSize - Size of the current segment in bytes
     * @param duration - Duration of the segment in seconds
     * @param sampleRate - Audio sample rate in Hz
     * @param channelCount - Number of audio channels
     * @param bitDepth - Bit depth of the audio
     */
    public updateAudioMetrics(
        source: 'demuxer' | 'mediastream',
        newMetrics: Partial<AudioMetrics> & { duration: number; segmentSize: number },
    ): void {
        let metrics = this._audioMetrics[source].get(this._mode);

        if (!metrics) {
            metrics = {
                sampleRate: newMetrics.sampleRate ?? this._configuredAudio?.sampleRate ?? 0,
                channelCount:
                    newMetrics.channelCount ?? this._configuredAudio?.channelCount ?? 0,
                bitDepth: newMetrics.bitDepth ?? this._configuredAudio?.bitDepth ?? 0,
                avgBitrate: 0,
                peakBitrate: 0,
                minBitrate: Infinity,
                totalBytes: 0,
                totalDuration: 0,
                sampleCount: 0,
                segmentSize: newMetrics.segmentSize ?? 0,
                duration: newMetrics.duration ?? 0,
            };
            this._audioMetrics[source].set(this._mode, metrics);
        }

        metrics.sampleRate = newMetrics.sampleRate ?? this._configuredAudio?.sampleRate ?? 0;
        metrics.channelCount =
            newMetrics.channelCount ?? this._configuredAudio?.channelCount ?? 0;
        metrics.bitDepth = newMetrics.bitDepth ?? this._configuredAudio?.bitDepth ?? 0;
        metrics.duration = newMetrics.duration ?? 0;
        metrics.segmentSize = newMetrics.segmentSize ?? 0;
        // Round duration to 3 decimal places
        const roundedDuration = Number(newMetrics?.duration.toFixed(3));
        // Calculate bitrate in kbps
        const bitrate = Number(
            ((newMetrics.segmentSize * 8) / roundedDuration / 1000).toFixed(3),
        );
        metrics.totalBytes += newMetrics.segmentSize;
        metrics.totalDuration = Number((metrics.totalDuration + roundedDuration).toFixed(3));
        metrics.sampleCount++;
        metrics.avgBitrate = Number(
            ((metrics.totalBytes * 8) / metrics.totalDuration / 1000).toFixed(3),
        );
        metrics.peakBitrate = Number(Math.max(metrics.peakBitrate, bitrate).toFixed(3));
        metrics.minBitrate = Number(Math.min(metrics.minBitrate, bitrate).toFixed(3));

        // Emit analytics event every 10 samples
        if (metrics.sampleCount % 10 === 0) {
            this._onRecorderEvent({
                type: 'info',
                recorder: this._mode,
                context: 'audio_analytics',
                message: `Audio Metrics for ${this._mode} on the ${source}`,
                attributes: {
                    source,
                    mode: this._mode,
                    metrics,
                },
            });
        }
    }

    /**
     * Extracts audio track information from a map of track info.
     * @param tracks - Map of track IDs to track info
     * @returns Object containing audio track details if found, undefined otherwise
     */
    public getAudioTrackInfo(tracks: Map<number, TrackInfo>):
        | {
              sampleRate: number;
              channelCount: number;
              bitDepth: number;
          }
        | undefined {
        const audioTrack = Array.from(tracks.values()).find(
            (track) => track.type === TrackType.AUDIO,
        );

        if (!audioTrack?.sampleRate || !audioTrack?.channels || !audioTrack?.bitDepth) {
            return undefined;
        }

        return {
            sampleRate: audioTrack.sampleRate,
            channelCount: audioTrack.channels,
            bitDepth: audioTrack.bitDepth,
        };
    }

    public setAudioMetrics(source: 'demuxer' | 'mediastream', metrics: AudioMetrics) {
        this._audioMetrics[source].set(this._mode, metrics);
    }
}
