// Copyright 2024 Descript, Inc
import { Microseconds, OneOrMany } from '@descript/descript-core';
import {
    CompositionId,
    TextRange,
    CardId,
    TimeRange,
    Word,
    TranscriptionLanguage,
    CardLayerId,
} from '@descript/descript-model';

export enum RecorderContext {
    WEB = 'editor-web',
    ROOMS = 'rooms',
}

export enum RecoveryState {
    CHECKING = 'checking',
    NO_RECOVERIES = 'no_recoveries',
    RECOVERING = 'recovering',
    COMPLETED = 'completed',
}

export type RecoveryStatus = {
    state: RecoveryState;
    total?: number;
    completed?: number;
    currentProject?: string; // only used in rooms recovery
    currentDelegateToken?: string; // only used in rooms recovery
    currentParticipantId?: string; // only used in rooms recovery
    currentParticipantName?: string; // only used in rooms recovery
};

export type WebRecordingSession = {
    sessionId: string;
    projectId: string;
    userName?: string;
    compositionId?: CompositionId;
    backendWorkflowId?: string;
    backendWorkflowStartTime?: Date;
    localStartTime?: Date;
    originalStartTime: Date;
    trackedSelectionId?: string;
    driveId?: string;
    recorders: Map<string, RecorderMetadata>;
    target?: DynamicRecordingTarget;
    transcriptionLanguage: TranscriptionLanguage;
    microphoneTrackSettings: {
        transcriptionEnabled: boolean;
        studioSoundEnabled: boolean;
        studioSoundIntensity: number;
        speakerId?: string;
    };
    computerAudioTrackSettings: {
        transcriptionEnabled: boolean;
        studioSoundEnabled: boolean;
        studioSoundIntensity: number;
        speakerId?: string;
    };

    equipment?: Map<string, RecordingEquipment>; // Captures the devices used for recording
    environment?: RecordingEnvironment; // Captures the browser, operating system, and device kind used for recording
    fpsAnalytics?: FPSAnalytics;
    resolutionAnalytics?: ResolutionReport;
    bitrateAnalytics?: BitrateAnalytics;
    audioAnalytics?: AudioAnalytics;
    isLiveTxEnabledByFeatureFlag?: boolean; // Holds the Feature Flag value to allow for checking in the recorder
    recordingFolderName?: string;
};

export type RecordingEquipment = {
    deviceId: string;
    groupId?: string;
    kind?: string;
    label?: string;
    isElectron?: boolean; // Screen only
    includeSystemAudio?: boolean; // Screen only
};

export type RecordingEnvironment = {
    user_agent?: string;
    app_version?: string;
    platform?: string;
    browser?: string;
    browser_version?: string;
    os?: string;
    os_version?: string;
    device?: string;
    arch?: string;
};

export type RecorderMetadata = {
    /** The asset GUID of the asset created for progressively-uploaded recordings */
    assetGuid?: string;
    /**
     * The GUID of the segmented artifact created during a progressive upload recording.
     * This will only be set once the placeholder artifact has been created on the backend
     */
    artifactGuid?: string;
    /** The GUID of the initial asset created during recording in the case of recovery */
    recoveryOriginalAssetId?: string;
    /** The GUID of the original artifact created during recording in the case of recovery */
    recoveryOriginalArtifactId?: string;
    /**
     * The state of the segmented artifact created during a progressive upload recording.
     * reified = the artifact has been reified (once the recorder has written all segments to disk,
     *  the segment count is known, and the reify API call has completed)
     * committed = the artifact has been committed (once all segments have been synced to the API
     *  and the commit API call has completed)
     */
    segmentedArtifactState?: 'reified' | 'committed';
    /** This property is used to align the recorder individual tracks relative to each other */
    recordingStartTimecode?: number;
    fileExtension?: string;
    mode?: MediaConfig['mode'];
    segmentCount?: number;
    hasVideo?: boolean;
    hasAudio?: boolean;
    audioSettings?: MediaTrackSettings;
    videoSettings?: MediaTrackSettings;
    duration_sec?: number;
    size_mb?: number;
    equipment?: RecordingEquipment;
    /** Whether or not this recorder is live transcribing */
    liveTranscribe?: boolean;

    /** Whether or not the recorder has successfully signaled the workflow for the recording track */
    track_created?: boolean;
};

/**  The MediaConfig is configuration for a given recorder instance.
 mode + deviceId are used like a composite primary key to identify recorder instances. */
type AVMediaConfig = {
    mode: 'audio' | 'video';
    deviceID?: string;
    audioDeviceID?: string;
    replaceableAudio?: boolean;
    replaceableVideo?: boolean;
};
type ScreenMediaConfig = {
    mode: 'screen';
    deviceID?: string;
    isElectron?: boolean;
    includeSystemAudio?: boolean;
    region?: Region;
};
export type MediaConfig = AVMediaConfig | ScreenMediaConfig;

type CardStep = {
    cardId: CardId;
    /** Time range of the recording in this card */
    recordingRange: TimeRange;
};
export type CardSteps = readonly CardStep[];

/*
 * DYNAMIC RECORDING TARGETS AND STATIC RECORDING TARGETS
 *
 * The RecordingTarget types depend on a fixed insert location for `script` and `replace-selection`
 * target types. This is necessary because the model needs to know exactly where in the script to
 * insert media for these recordings. However, the insert locations can change while recording if
 * the user edits or deletes text before the insert location. We use the TrackedSelection api to
 * handle this. We cannot pass TrackedSelections into the document reducer, though, since it is not
 * a saga and therefore we can't use selectors to evaluate its value. For this reason, we create
 * DynamicTarget types which are used during recording, and then convert them to the fixed
 * RecordingTargets when it is time to send them to the document reducer
 */

////////
// MARK: Dynamic Recording Targets

export type DynamicScriptRecordingTarget = {
    type: ScriptRecordingTarget['type'];
    compositionId: CompositionId;
    trackedSelectionId: string;
};

export type DynamicReplaceSelectionRecordingTarget = {
    type: ReplaceSelectionRecordingTarget['type'];
    compositionId: CompositionId;
    trackedSelectionId: string;
};

export type DynamicRecordingTarget =
    | DynamicScriptRecordingTarget
    | DynamicReplaceSelectionRecordingTarget
    | CardRecordingTarget
    | CardSteppingRecordingTarget
    | ReplaceCardRecordingTarget;

////////
// MARK: Static Recording Targets

export type CardSteppingRecordingTarget = {
    type: 'card-stepping';
    compositionId: CompositionId;
    steps: CardSteps;
};

export type ReplaceSelectionRecordingTarget = {
    type: 'replace-selection';
    compositionId: CompositionId;
    /** Text range in composition rendered string */
    range: TextRange;
};

export type ScriptRecordingTarget = {
    type: 'script';
    compositionId: CompositionId;
    /** Text location in composition rendered string */
    insertLocation: number;
};

export type CardRecordingTarget = {
    type: 'card';
    compositionId: CompositionId;
    cardId: CardId;
};

export type ReplaceCardRecordingTarget = {
    type: 'replace-layer';
    compositionId: CompositionId;
    cardLayers?: OneOrMany<CardLayerId>;
};

export type RecordingTarget =
    | ScriptRecordingTarget
    | CardRecordingTarget
    | ReplaceSelectionRecordingTarget
    | ReplaceCardRecordingTarget
    | CardSteppingRecordingTarget;

export type RecordingEvent =
    | {
          type: 'start'; // MediaRecorder API
          metadata: WebRecorderStartMetadata;
      }
    | {
          type: 'stop'; // MediaRecorder API
          metadata: WebRecorderStopMetadata;
      }
    | {
          type: 'ondataavailable'; // MediaRecorder API
          blob: Blob;
          metadata: WebRecorderSegmentMetadata;
      }
    | {
          type: 'start_workflow';
          sessionId: string;
          waitForRecorders: boolean;
      }
    | {
          type: 'run_recovery';
          props: RunRecoveryProps;
      }
    | {
          type: 'error';
          recorder: MediaConfig['mode'] | 'N/A';
          context: RecordingEventContext;
          error: Error;
          deviceId?: string;
          audioDeviceId?: string;
          recovery?: boolean;
      }
    | {
          type: 'warning';
          recorder: MediaConfig['mode'] | 'N/A';
          context: RecordingEventContext;
          message: string | unknown;
          attributes?: unknown;
      }
    | {
          type: 'info';
          recorder: MediaConfig['mode'] | 'N/A';
          context: RecordingEventContext;
          message: string | unknown;
          attributes?: unknown;
      }
    | { type: 'transcription_cancelled'; metadata: WebRecorderTranscriptionMetadata }
    | { type: 'transcription_success'; metadata: WebRecorderTranscriptionMetadata };

export type WebRecordingSegment = {
    /**
     * The blob event for the segment.
     */
    blob: Blob;

    /**
     * The Web Recorder chunk metadata for the segment.
     */
    segmentMetadata: WebRecorderSegmentMetadata;
};

export type WebRecorderSegmentMetadata = {
    recorderId: string;
    recordingSessionId: string;
    mode: MediaConfig['mode'];
    chunkNumber: number;
    chunkStartOffset: Microseconds;
    chunkDuration: Microseconds;
    isInit: boolean;
    recordingStartTimecode: number | undefined;
    fileExtension: string;
};
export type WebRecorderStartMetadata = {
    recorderId: string;
    recordingSessionId: string;
    mode: MediaConfig['mode'];
    fileExtension: string;
    hasVideo: boolean;
    hasAudio: boolean;
    liveTranscribe: boolean;
    audioSettings?: MediaTrackSettings;
    videoSettings?: MediaTrackSettings;
    equipment?: RecordingEquipment;
};
export type WebRecorderStopMetadata = {
    recorderId: string;
    recordingSessionId: string;
    mode: MediaConfig['mode'];
    terminated: boolean;
};

export type WebRecorderTranscriptionMetadata = {
    type: 'done';
    mode: MediaConfig['mode'];
    sessionId: string;
    perChannelAlignments: Word[][];
    formattedText: string;
};

export enum KnownErrors {
    // browser errors
    PERMISSION_DENIED_ERROR = 'NotAllowedError',
    DEVICE_IN_USE_ERROR = 'NotReadableError',

    // our errors
    TRACK_ENDED_PREMATURELY = 'TrackEndedPrematurely',
    INIT_SEGMENT_TIMEOUT = 'InitSegmentTimeout',
    INIT_SEGMENT_NO_AUDIO = 'InitSegmentNoAudio',
    INIT_SEGMENT_NO_VIDEO = 'InitSegmentNoVideo',
}
export enum KnownWarnings {
    RECORDING_DURATION_LIMIT_REACHED = 'RecordingDurationLimitReached',
}

export type RecordingEventContext =
    // metrics
    | 'fps_analytics'
    | 'resolution_analytics'
    | 'bitrate_analytics'
    | 'audio_analytics'

    // general
    | 'recorder_start'
    | 'recorder_stop'
    | 'recorder_setup'
    | 'recorder_setup_inputs'
    | 'recorder_replace_video'
    | 'recorder_replace_audio'
    | 'recorder_get'
    | 'recorder_kill'

    // decoders
    | 'matroska_decoder'
    | 'mp4_decoder'

    // saga
    | 'media_artifact'
    | 'media_asset'
    | 'media_upload'

    // backend
    | 'workflow_start'
    | 'workflow_stop'

    // span handler
    | 'span_handler'

    // live transcription
    | 'transcription_cancelled'
    | 'transcription_success'
    | 'transcription_stop'
    | 'transcription_start'

    // instant playback
    | 'instant_playback'

    // known errors
    | KnownErrors
    // known warnings
    | KnownWarnings;

export interface Permission {
    video: boolean;
    audio: boolean;
}

export enum MediaDeviceKinds {
    MICROPHONES = 'audioinput',
    HEADPHONES = 'audiooutput',
    CAMERAS = 'videoinput',
}

export type VideoResLabel = keyof typeof VIDEO_RES;

export const VIDEO_RES = {
    '480p': { height: 480, width: 854 },
    '720p': { height: 720, width: 1280 },
    '1080p': { height: 1080, width: 1920 },
    '1440p': { height: 1440, width: 2560 },
    '4k': { height: 2160, width: 3840 },
};

export enum AudioMimeTypes {
    PCM = 'audio/webm;codecs=pcm',
    M4A = 'audio/mp4',
    OPUS = 'audio/webm;codecs=opus',
}

export enum VideoMimeTypes {
    MP4 = 'video/mp4',
    AVC1 = 'video/x-matroska;codecs=avc1',
    AVC1_PCM = 'video/x-matroska;codecs=avc1,pcm',
    AVC1_OPUS = 'video/x-matroska;codecs=avc1,opus',
    VP8 = 'video/webm;codecs=vp8',
    VP8_OPUS = 'video/webm;codecs=vp8,opus',
    VP9_OPUS = 'video/webm;codecs=vp9,opus',
}

export const MimeTypeToFileExtension = {
    'video/mp4': 'mp4',
    'video/x-matroska;codecs=avc1': 'mkv',
    'video/x-matroska;codecs=avc1,pcm': 'mkv',
    'video/x-matroska;codecs=avc1,opus': 'mkv',
    'video/webm;codecs=vp8': 'webm',
    'video/webm;codecs=vp8,opus': 'webm',
    'video/webm;codecs=vp9,opus': 'webm',
    'audio/mp4': 'm4a',
    'audio/webm;codecs=pcm': 'mkv',
    'audio/webm;codecs=opus': 'ogg',
    // 'video/mp4;codecs=avc1,opus': 'mp4',
    // 'video/mp4;codecs=avc1': 'mp4',
};

export const WebRecorderTimesMS = {
    AUDIO_CHUNK_LENGTH: 4 * 1000, // 4 seconds
    VIDEO_CHUNK_LENGTH: 4 * 1000, // 4 seconds
    RECORDING_TIME_LIMIT: 4.5 * 1000 * 60 * 60, // 4.5 hours
    INIT_SEGMENT_TIMEOUT: 60 * 1000, // 60 seconds
    RECORDING_START_TIMEOUT: 6 * 1000, // 6 seconds
    REPLACABLE_TRACK_INITIAL_VIDEO_METADATA_LOAD_TIMEOUT: 3 * 1000, // 3 seconds
};

export type RecoveryResults = {
    workflowId: string | undefined;
    session: WebRecordingSession | undefined;
};

export type UpdateWorkflowInput = {
    artifactId: string;
    backendWorkflowId: string;
};

export type RunRecoveryProps = {
    recoveryReason: string;
    userId: string;
    onStatusChanged?: (s: RecoveryStatus) => void; // will get called with the recovery status every time it changes
    incrementTotalSegmentCount?: (recorderId: string) => void; // used for rooms upload status on recovery page
    incrementLastSegmentUploaded?: (
        metadata: WebRecorderSegmentMetadata,
        delegateToken?: string,
    ) => void; // used for rooms upload status on recovery page
    onArtifactCommitted?: (recorderId: string) => void; // used for rooms upload status on recovery page
    singleSessionId?: string; // If provided, skip the segmentCount check and recover this one session
};

export type SaveRecoverySegmentInput = WebRecoveryMetadata & {
    buf: ArrayBuffer;
    segmentMetadata: WebRecorderSegmentMetadata;
};

export type WebRecordingRecoverySession = Omit<WebRecordingSession, 'recorders'> & {
    recordersObj: Record<string, RecorderMetadata>;
};

export type MoveRecoveryTempDirectoryInput = {
    tempId: string;
    artifactId: string;
    assetId?: string;
};

export type WebRecoveryMetadata = {
    session: WebRecordingRecoverySession;
    info: {
        savedAt: number;
        recorderId: string;
        artifactId: string;
        userId: string;
        assetId?: string;
        segmentCount?: number;
        recoveryTarget?: RecordingTarget;
        delegateToken?: string;
        recorderContext?: RecorderContext;
        trackSkew?: number;
        participantId?: string;
        participantName?: string;
    };
};
export type WebRecoveryPlaylist = {
    segment_0_is_init_file: boolean;
    start_times: number[];
    end_time: number;
};

export type ArtifactRecoveryInfo = WebRecoveryMetadata & {
    segments: WebRecorderSegmentMetadata[];
    playlist?: WebRecoveryPlaylist;
};

export const enum RecordingAnalyticsEvents {
    'recorder_started' = 'recorder_started',
    'recorder_stopped' = 'recorder_stopped',
    'recorder_terminated' = 'recorder_terminated',
    'recorder_completed' = 'recorder_completed',
    'recording_started' = 'recording_started',
    'recording_stopped' = 'recording_stopped',
    'recording_terminated' = 'recording_terminated',
    'recording_completed' = 'recording_completed',
    'all_uploads_completed' = 'all_uploads_completed',
    'modal_shown' = 'recording_modal_shown',
    'recorder_fps_metrics' = 'recorder_fps_metrics',
    'recorder_resolution_metrics' = 'recorder_resolution_metrics',
    'recorder_bitrate_metrics' = 'recorder_bitrate_metrics',
    'recorder_audio_metrics' = 'recorder_audio_metrics',
}

export const enum RecordingAnalyticsModalType {
    'user_upload_stalled' = 'in_rooms_upload_stalled',
    'participants_stalled' = 'in_editor_uploads_stalled',
    'uploading_modal' = 'in_editor_uploading_modal',
}

export type RecordingEventProps = {
    session_id: string;
    kind: 'audio' | 'screen' | 'camera' | 'unknown';
    has_computer_audio?: boolean;
    has_camera?: boolean;
    has_microphone?: boolean;
    has_screen?: boolean;
    context?: string;
    target?: RecordingTarget['type'];
    default_camera_resolution?: string;
    default_screen_resolution?: string;
    camera_height?: number;
    camera_width?: number;
    screen_height?: number;
    screen_width?: number;
    duration_sec?: number;
    audio_size_mb?: number;
    video_size_mb?: number;
    screen_size_mb?: number;
    transcription?: boolean;
    audio_echo_cancellation?: boolean;
    screen_echo_cancellation?: boolean;
    studio_sound?: boolean;
    countdown?: boolean;
    audio_device_label?: string;
    camera_device_label?: string;
    user_agent?: string;
    app_version?: string;
    platform?: string;
    browser?: string;
    browser_version?: string;
    os?: string;
    os_version?: string;
    device?: string;
    arch?: string;
    resolution_analytics?: {
        configured_resolution?: {
            width?: number;
            height?: number;
            pixel_total?: number;
        };
        actual_resolution?: {
            width?: number;
            height?: number;
            pixel_total?: number;
        };
        delta_resolution?: {
            width?: number;
            height?: number;
            pixel_total?: number;
        };
    };
    fps_analytics?: {
        mode?: string;
        configured_fps?: number;
        media_stream?: {
            avg_fps?: number;
            most_common_range?: string;
            most_common_count?: number;
            best_fps?: number;
            worst_fps?: number;
            total_samples?: number;
        };
        demuxer?: {
            avg_fps?: number;
            most_common_range?: string;
            most_common_count?: number;
            best_fps?: number;
            worst_fps?: number;
            total_samples?: number;
        };
        delta_metrics?: {
            avg_fps_delta?: number;
            sample_count_delta?: number;
            worst_fps_delta?: number;
            best_fps_delta?: number;
        };
    };
    bitrate_analytics?: {
        mode?: string;
        demuxer?: {
            avg_bitrate?: number;
            peak_bitrate?: number;
            min_bitrate?: number;
            total_bytes?: number;
            total_duration?: number;
            sample_count?: number;
        };
        media_stream?: {
            avg_bitrate?: number;
            peak_bitrate?: number;
            min_bitrate?: number;
            total_bytes?: number;
            total_duration?: number;
            sample_count?: number;
        };
        configured_bitrate?: number;
    };
    audio_analytics?: {
        mode: 'screen' | 'video' | 'audio';
        demuxer?: {
            sample_rate?: number;
            channel_count?: number;
            bit_depth?: number;
            avg_bitrate?: number;
            peak_bitrate?: number;
            min_bitrate?: number;
            total_bytes?: number;
            total_duration?: number;
            sample_count?: number;
            clipping_count?: number;
            silence_count?: number;
            noise_floor?: number;
            peak_amplitude?: number;
        };
        media_stream?: {
            sample_rate?: number;
            channel_count?: number;
            bit_depth?: number;
            avg_bitrate?: number;
            peak_bitrate?: number;
            min_bitrate?: number;
            total_bytes?: number;
            total_duration?: number;
            sample_count?: number;
            clipping_count?: number;
            silence_count?: number;
            noise_floor?: number;
            peak_amplitude?: number;
        };
        configured_audio?: {
            sample_rate?: number;
            channel_count?: number;
            bit_depth?: number;
        };
    };
};

export type Region = {
    x: number;
    y: number;
    width: number;
    height: number;
};

export type RegionTuple = [originX: number, originY: number, width: number, height: number];

export type FPSBucket = {
    min: number;
    max: number;
    count: number;
};

export type FPSHistogram = {
    source: 'demuxer' | 'mediastream';
    mode: 'screen' | 'video';
    buckets: FPSBucket[];
    recentTimestamps?: number[];
};

export type FPSMetrics = {
    avgFPS: number;
    mostCommonRange: string;
    mostCommonCount: number;
    bestFPS: number;
    worstFPS: number;
    totalSamples: number;
};
export type FPSDeltaMetrics = {
    avgFPSDelta: number;
    sampleCountDelta: number;
    worstFPSDelta: number;
    bestFPSDelta: number;
};

export type FPSAnalytics = {
    mode: 'screen' | 'video';
    demuxer: FPSMetrics | undefined;
    mediaStream?: FPSMetrics | undefined;
    deltaMetrics?: FPSDeltaMetrics | undefined;
    configuredFPS?: number | undefined;
};

export type Resolution = {
    width: number;
    height: number;
    pixelTotal: number;
};
export type ResolutionReport = {
    configuredResolution: Resolution; // MediaStream VideoTrack Resolution
    actualResolution: Resolution; // Demuxer Resolution
    deltaResolution: Resolution; // Difference between configured and actual resolution
};

export type BitrateMetrics = {
    avgBitrate: number; // in bits per second
    peakBitrate: number; // highest bitrate seen
    minBitrate: number; // lowest bitrate seen
    totalBytes: number; // total bytes processed
    totalDuration: number; // total duration in seconds
    sampleCount: number; // number of segments analyzed
};

export type BitrateAnalytics = {
    mode: 'screen' | 'video';
    demuxer: BitrateMetrics | undefined;
    mediaStream?: BitrateMetrics | undefined;
    configuredBitrate?: number | undefined;
};

export type AudioMetrics = {
    sampleRate: number;
    channelCount: number;
    bitDepth: number;
    avgBitrate: number;
    peakBitrate: number;
    minBitrate: number;
    totalBytes: number;
    totalDuration: number;
    sampleCount: number;
    segmentSize: number;
    duration: number;
};

export type AudioTrackInfo = {
    sampleRate: number;
    channelCount: number;
    bitDepth: number;
};

export type AudioAnalytics = {
    mode: 'screen' | 'video' | 'audio';
    demuxer: AudioMetrics | undefined;
    mediaStream?: AudioMetrics | undefined;
    configuredAudio?: AudioTrackInfo | undefined;
};
