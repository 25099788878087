// Copyright 2024 Descript, Inc
import { DebugSettings, PlatformHelpers } from '@descript/descript-core';
import { Region, RegionTuple, WebRecorderTimesMS } from '@descript/recorder-base';
import { WebRecorder } from '../recorder/recorder';
import { DescriptError, ErrorCategory } from '@descript/errors';
import { isSafari } from '../device/device';
// Copyright 2024 Descript, Inc
const MAX_ASPECT_RATIO = 3.6; // to allow some leeway for ultra-wide screens triple montior max is around 3.55
const MIN_ASPECT_RATIO = 1 / 2; // to allow some leeway for vertical videos/screens average phone is around 9/16

const MIN_RESOLUTION = 640 * 360; // 360p
// const MIN_RESOLUTION = 1280 * 720; // 720p
// const MIN_RESOLUTION = 1920 * 1080; // 1080p

// const MAX_RESOLUTION = 1920 * 1080; // 1080p
// const MAX_RESOLUTION = 2560 * 1440; // 1440p
const MAX_RESOLUTION = 4096 * 2160; // 2160p - A.K.A. 4K

/**
 * Given video dimensions, return a new set of canvas dimensions that adhere
 * to the above Aspect Ratio and Resolution contraints
 * while maximizing the amount of area covered by the video
 */
export async function getCanvasDimensionsForMediaStream(mediaStream: MediaStream) {
    const video = document.createElement('video');
    video.srcObject = mediaStream;
    video.muted = true;

    let videoWidth = 1920;
    let videoHeight = 1080;

    // Wait for video metadata to load to get the video dimensions
    await new Promise((resolve) => {
        const timeout = setTimeout(() => {
            // When a recorder that can replace video tracks mid recording is created,
            // we need to create a canvas and draw the video on top of it,
            // to initialize the canvas and know what size it should be,
            // we wait for the initial video's metadata to load, if it doesn't load in time,
            // will use the default 1080p canvas size
            WebRecorder.onRecorderEvent({
                type: 'error',
                recorder: 'N/A',
                context: 'recorder_setup',
                error: new DescriptError(
                    `Initial video metadata load timeout, defaulting to 1080p canvas size. Make sure to pass a proper initial VideoTrack when using "replaceableVideo: true" or screen recording.`,
                    ErrorCategory.WebRecorder,
                ),
            });
            resolve(0);
        }, WebRecorderTimesMS.REPLACABLE_TRACK_INITIAL_VIDEO_METADATA_LOAD_TIMEOUT);
        video.addEventListener('loadedmetadata', () => {
            videoWidth = video.videoWidth;
            videoHeight = video.videoHeight;
            clearTimeout(timeout);
            resolve(0);
        });
    });

    const videoAspect = videoWidth / videoHeight;

    const targetAspectRatio =
        videoAspect < MIN_ASPECT_RATIO
            ? MIN_ASPECT_RATIO
            : videoAspect > MAX_ASPECT_RATIO
              ? MAX_ASPECT_RATIO
              : videoAspect;

    let targetWidth = videoWidth;
    let targetHeight = videoHeight;

    // Adjust dimensions to match the target aspect ratio, if needed
    if (targetAspectRatio !== videoAspect) {
        if (targetAspectRatio > videoAspect) {
            // Increase width to match the target aspect ratio
            targetWidth = targetHeight * targetAspectRatio;
        } else {
            // Increase height to match the target aspect ratio
            targetHeight = targetWidth / targetAspectRatio;
        }
    }

    // Scale dimensions to fit within resolution constraints
    const targetResolution = targetWidth * targetHeight;
    if (targetResolution > MAX_RESOLUTION) {
        // Scale down proportionally to max resolution
        const scale = Math.sqrt(MAX_RESOLUTION / targetResolution);
        targetWidth *= scale;
        targetHeight *= scale;
    } else if (targetResolution < MIN_RESOLUTION) {
        // Scale up proportionally to min resolution
        const scale = Math.sqrt(MIN_RESOLUTION / targetResolution);
        targetWidth *= scale;
        targetHeight *= scale;
    }

    return { width: Math.round(targetWidth), height: Math.round(targetHeight) };
}

/**
 * Given a sized canvas and a videoFrame, return the coordinates needed to draw
 * the video within the canvas.
 *
 * Note - this uses a canvas coordinate system with (0,0) being the top left corner
 */
export function getVideoDimensionsForCanvas(
    canvas: HTMLCanvasElement,
    videoFrame: { displayWidth: number; displayHeight: number },
): RegionTuple {
    const canvasAspectRatio = canvas.width / canvas.height;
    const videoAspectRatio = videoFrame.displayWidth / videoFrame.displayHeight;
    const originX =
        videoAspectRatio > canvasAspectRatio
            ? 0
            : (canvas.width - canvas.height * videoAspectRatio) / 2;
    const originY =
        videoAspectRatio > canvasAspectRatio
            ? (canvas.height - canvas.width / videoAspectRatio) / 2
            : 0;
    const videoDrawWidth =
        videoAspectRatio > canvasAspectRatio ? canvas.width : canvas.height * videoAspectRatio;
    const videoDrawHeight =
        videoAspectRatio > canvasAspectRatio ? canvas.width / videoAspectRatio : canvas.height;

    return [
        Math.round(originX),
        Math.round(originY),
        Math.round(videoDrawWidth),
        Math.round(videoDrawHeight),
    ];
}

function writeVideoTrackToCanvasWithoutTrackProcessor(
    videoTrack: MediaStreamTrack,
    canvas: HTMLCanvasElement,
    targetFPS: number,
    region?: Region,
    mediaStream?: MediaStream,
) {
    const useFpsLogic = DebugSettings.getValue(
        'web-recorder.web_screenshare_use_fps_logic',
        true,
    );
    const renderFps = DebugSettings.getValue('web-recorder.web_screenshare_render_fps', false);

    const targetFrametime = 1000 / targetFPS;
    const frameSkipThreshold = 1.1;
    let lastWriteEndTimestamp = 0;
    let lastDrawEndTimestamp = 0;
    let drawOverheadMs = 0;

    const videoElement = document.createElement('video');
    const stream = mediaStream ?? new MediaStream([videoTrack]);
    videoElement.srcObject = stream;
    videoElement.muted = true;

    const ctx = canvas.getContext('2d', { alpha: false });
    if (!ctx) {
        throw new DescriptError(
            'Canvas 2D context is not available',
            ErrorCategory.WebRecorder,
        );
    }

    const abortController = new AbortController();
    const abortSignal = abortController.signal;

    const writeBlackFrameIfSourceEnds = setInterval(() => {
        if (videoTrack.readyState !== 'live') {
            clearInterval(writeBlackFrameIfSourceEnds);
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        }
    }, 500);

    function drawFrame() {
        if (abortSignal.aborted) {
            videoElement.pause();
            // eslint-disable-next-line no-null/no-null
            videoElement.srcObject = null;
            clearInterval(writeBlackFrameIfSourceEnds);
            return;
        }

        if (!ctx) {
            throw new DescriptError(
                'Canvas 2D context is not available',
                ErrorCategory.WebRecorder,
            );
        }

        // Have it so it skips this frame if the last frame took too long to draw
        const skipFrame = drawOverheadMs > targetFrametime;
        if (!useFpsLogic || !skipFrame) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            if (region) {
                ctx.drawImage(
                    videoElement,
                    region.x,
                    region.y,
                    region.width,
                    region.height,
                    0,
                    0,
                    region.width,
                    region.height,
                );
            } else {
                const drawCoordinates = getVideoDimensionsForCanvas(canvas, {
                    displayWidth: videoElement.videoWidth,
                    displayHeight: videoElement.videoHeight,
                });
                ctx.drawImage(videoElement, ...drawCoordinates);
            }

            if (renderFps) {
                const fps = 1000 / (performance.now() - lastDrawEndTimestamp);
                ctx.fillStyle = 'white';
                ctx.font = `${canvas.height / 10}px sans-serif`;
                ctx.fillText(`FPS: ${fps.toFixed(2)}`, 10, 0 + canvas.height / 10);
            }
            lastDrawEndTimestamp = performance.now();
        }

        const end = performance.now();
        const frametime = end - (lastWriteEndTimestamp || end);
        lastWriteEndTimestamp = end;

        if (useFpsLogic) {
            if (skipFrame) {
                drawOverheadMs = Math.max(0, drawOverheadMs - frametime);
            } else if (frametime > targetFrametime * frameSkipThreshold) {
                drawOverheadMs += frametime - targetFrametime;
            }
        }

        requestAnimationFrame(drawFrame);
    }

    videoElement.addEventListener('loadedmetadata', async () => {
        try {
            await videoElement.play();
            requestAnimationFrame(drawFrame);
        } catch (e) {
            throw new DescriptError(
                `Unable to play offscreen video: ${e}`,
                ErrorCategory.WebRecorder,
            );
        }
    });

    return () => {
        abortController.abort('abort requested by caller');
    };
}

function writeVideoTrackToCanvasWithTrackProcessor(
    videoTrack: MediaStreamTrack,
    canvas: HTMLCanvasElement,
    targetFPS: number,
    region?: Region,
) {
    const useFpsLogic = DebugSettings.getValue(
        'web-recorder.web_screenshare_use_fps_logic',
        true,
    );
    const renderFps = DebugSettings.getValue('web-recorder.web_screenshare_render_fps', false);

    const targetFrametime = 1000 / targetFPS;
    const frameSkipThreshold = 1.1;
    let lastWriteEndTimestamp = 0;
    let lastDrawEndTimestamp = 0;
    let drawOverheadMs = 0;

    // @ts-expect-error Experimental: https://developer.mozilla.org/en-US/docs/Web/API/MediaStreamTrackProcessor
    // We are using this experimental API to run the draw loop. It will execute the "write" function on every frame
    const trackProcessor = new MediaStreamTrackProcessor({ track: videoTrack });

    const ctx = canvas.getContext('2d', { alpha: false });
    if (!ctx) {
        throw new DescriptError(
            'Canvas 2D context is not available',
            ErrorCategory.WebRecorder,
        );
    }

    const writeBlackFrameIfSourceEnds = setInterval(() => {
        if (videoTrack.readyState !== 'live') {
            clearInterval(writeBlackFrameIfSourceEnds);
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        }
    }, 500);

    const writable = new WritableStream({
        write: (videoFrame: VideoFrame) => {
            // have it so it skips this frame if the last frame took too long to draw
            const skipFrame = drawOverheadMs > targetFrametime;
            if (!useFpsLogic || !skipFrame) {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                if (region) {
                    ctx.drawImage(
                        videoFrame,
                        region.x,
                        region.y,
                        region.width,
                        region.height,
                        0,
                        0,
                        region.width,
                        region.height,
                    );
                } else {
                    const drawCoordinates = getVideoDimensionsForCanvas(canvas, videoFrame);
                    ctx.drawImage(videoFrame, ...drawCoordinates);
                }

                if (renderFps) {
                    const fps = 1000 / (performance.now() - lastDrawEndTimestamp);
                    ctx.fillStyle = 'white';
                    ctx.font = `${canvas.height / 10}px sans-serif`;
                    ctx.fillText(`FPS: ${fps.toFixed(2)}`, 10, 0 + canvas.height / 10);
                }
                lastDrawEndTimestamp = performance.now();
            }

            videoFrame.close();

            const end = performance.now();
            const frametime = end - (lastWriteEndTimestamp || end);
            lastWriteEndTimestamp = end;

            if (useFpsLogic) {
                if (skipFrame) {
                    drawOverheadMs = Math.max(0, drawOverheadMs - frametime);
                } else if (frametime > targetFrametime * frameSkipThreshold) {
                    drawOverheadMs += frametime - targetFrametime;
                }
            }
        },
    });

    const abortController = new AbortController();
    const abortSignal = abortController.signal;

    trackProcessor.readable.pipeTo(writable, { signal: abortSignal }).catch((e: Error) => {
        if (e.name !== 'AbortError') {
            return;
        }
        throw e;
    });

    return () => {
        abortController.abort('abort requested by caller');
    };
}

/**
 * Write a video track to a canvas element on every video frame. This function is consumed by "createCanvasMediaStreamFromMediaStream"
 * @param videoTrack the video track to draw frames from
 * @param targetFPS the target FPS to draw frames at
 * @param region region of the video to capture
 * @returns a function that should be called to stop the canvas writer to avoid memory leaks
 */
export function writeVideoTrackToCanvas(
    videoTrack: MediaStreamTrack,
    canvas: HTMLCanvasElement,
    targetFPS: number,
    region?: Region,
    mediaStream?: MediaStream,
) {
    // @ts-expect-error This API is only available in certain browsers
    if (window?.MediaStreamTrackProcessor !== undefined) {
        return writeVideoTrackToCanvasWithTrackProcessor(videoTrack, canvas, targetFPS, region);
    } else {
        return writeVideoTrackToCanvasWithoutTrackProcessor(
            videoTrack,
            canvas,
            targetFPS,
            region,
            mediaStream,
        );
    }
}

/**
 * Create a canvas media stream from a media stream so that we can manipulate the video frames
 * @param mediaStream the media stream to create a canvas media stream from
 * @param targetFPS FPS target to hit, if not provided, the frame rate of the video track will be used
 * @param region region of the video to capture
 * @param stopCanvasStreamTracksOnCreatorStreamTrackStop if true, stop the canvas stream tracks when the creator stream tracks stop, generally should set true for screen recording and false for camera recording
 * @returns a promise that resolves to an object containing the canvas media stream, the canvas element, and a function to stop the canvas writer to avoid memory leaks
 */
export async function createCanvasMediaStreamFromMediaStream(
    mediaStream: MediaStream,
    targetFPS?: number,
    region?: Region,
    stopCanvasStreamTracksOnCreatorStreamTrackStop = true,
) {
    const canvas = document.createElement('canvas');
    if (!canvas) {
        throw new DescriptError('Unable to create canvas element', ErrorCategory.WebRecorder);
    }

    const { width, height } = region
        ? region
        : await getCanvasDimensionsForMediaStream(mediaStream);

    // Limit canvas size to 1920x1080 on non-Mac devices aka Windows
    canvas.width = !PlatformHelpers.isMac() && width > 1920 ? 1920 : width;
    canvas.height = !PlatformHelpers.isMac() && height > 1080 ? 1080 : height;

    const videoTrack = mediaStream.getVideoTracks()[0];
    if (!videoTrack) {
        throw new DescriptError(
            'Unable to get video track from media stream',
            ErrorCategory.WebRecorder,
        );
    }

    // If targetFPS is not provided, use the frame rate of the video track
    if (!targetFPS) {
        targetFPS = videoTrack.getSettings().frameRate || 30;
    } else {
        // on Safari trying to apply constraints kills the media stream
        if (!isSafari()) {
            try {
                await videoTrack.applyConstraints({
                    ...videoTrack.getConstraints(),
                    frameRate: { ideal: targetFPS, max: targetFPS, min: 10 },
                });
            } catch (e) {
                // blank video streams cannot have constraints applied in FF so we need to swallow this error
                if (e instanceof Error && e.name !== 'OverconstrainedError') {
                    throw e;
                }
            }
        }
    }

    const ctx = canvas.getContext('2d', { alpha: false, willReadFrequently: true });
    if (!ctx) {
        throw new DescriptError(
            'Canvas 2D context is not available',
            ErrorCategory.WebRecorder,
        );
    }

    const keepAliveInterval = setInterval(() => {
        // This is needed to keep the stream alive so the mkv demuxer doesn't choke on the black video segments.
        // 15 fps is enough to keep the stream alive and we only need to write the existing 1 px back to the canvas
        const imgData = ctx.getImageData(0, 0, 1, 1);
        ctx.putImageData(imgData, 0, 0);
    }, 1000 / 15);

    let killCanvasWriter = () => {
        //noop
    };
    if (DebugSettings.getValue('web-recorder.screen_share_video_el', false)) {
        const video = document.createElement('video');
        video.srcObject = mediaStream;
        video.muted = true;

        const renderFrame = () => {
            const drawCoordinates = getVideoDimensionsForCanvas(canvas, {
                displayWidth: video.videoWidth,
                displayHeight: video.videoHeight,
            });
            ctx.drawImage(video, ...drawCoordinates);
            video.requestVideoFrameCallback(renderFrame);
        };

        video.onloadeddata = () => {
            video
                .play()
                .then(() => {
                    video.requestVideoFrameCallback(renderFrame);
                })
                .catch((e) => {
                    throw new DescriptError(
                        `Unable to play offscreen video: ${e}`,
                        ErrorCategory.WebRecorder,
                    );
                });
        };
    } else {
        killCanvasWriter = writeVideoTrackToCanvas(
            videoTrack,
            canvas,
            targetFPS,
            region,
            mediaStream,
        );
    }

    const canvasMediaStream = canvas.captureStream(targetFPS);

    // propagate ended signal from canvas to raw stream and vice-versa (e.g. close recording preview)
    canvasMediaStream.getTracks().forEach((track) => {
        const trackKind = track.kind;
        const listener = () => {
            if (track.kind === 'video') {
                clearInterval(keepAliveInterval);
            }

            track.removeEventListener('ended', listener);
            mediaStream.getTracks().forEach((t) => {
                if (t.kind !== trackKind) {
                    return;
                }
                t.stop();
                t.dispatchEvent(new Event('ended'));
            });
        };
        track.addEventListener('ended', listener);
    });

    if (stopCanvasStreamTracksOnCreatorStreamTrackStop) {
        mediaStream.getTracks().forEach((track) => {
            const trackKind = track.kind;
            const listener = () => {
                track.removeEventListener('ended', listener);
                canvasMediaStream.getTracks().forEach((t) => {
                    if (t.kind !== trackKind) {
                        return;
                    }
                    t.stop();
                    t.dispatchEvent(new Event('ended'));
                });
            };
            track.addEventListener('ended', listener);
        });
    }

    // propagate audio if also captured
    mediaStream.getAudioTracks().forEach((audioTrack) => {
        canvasMediaStream.addTrack(audioTrack);
    });

    return {
        canvasSourceTrack: videoTrack,
        canvasMediaStream,
        canvas,
        killCanvasWriter,
        sourceMediaStream: mediaStream,
    };
}
