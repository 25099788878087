// Copyright 2024 Descript, Inc
import * as ApiClient from '../../Api/ApiClient';
import { TraceEvent, errorCategoryContext } from '@descript/analytics';
import { ITransmission } from './transmission';
import { PlatformHelpers } from '@descript/descript-core';
import { DescriptError, ErrorCategory, Errors } from '@descript/errors';

const defaultCtx = errorCategoryContext(ErrorCategory.AppArchitecture);

type TimestampString = string;

type TimeoutId = ReturnType<typeof global.setTimeout>;

type UploadEventsBatchRequest = {
    client_sent_timestamp: TimestampString;
    events: TraceEvent[]; // This is JSON serializable
};

export interface InMemoryTransmissionOpts {
    dataset: string;
    /** If pending uploads exceed this, try to kick off a batch upload now */
    uploadSizeTrigger?: number;
    /**
     * Waiting period (in milliseconds) after logging an event before
     * uploading it (and others that have accumulated in that time), so that
     * we can batch up windows of events
     */
    batchTimeWindow?: number;
    /**
     * If there is a spike in events, there could be multiple concurrent
     * batches, due to a batch being kicked off whenever the number of
     * events in the queue exceeds `batchSizeTrigger`. This puts a limit
     * on the number of concurrent API calls we make for uploading events
     */
    maxConcurrentBatches?: number;
    /** Max event queue capacity before dropping events */
    maxPendingUploads?: number;
    /** Max number of times to retry before dropping events as undeliverable */
    maxBatchRetry?: number;
    /**
     * When retrying, this scales the exponential backoff, so the backoff will
     * be between 0 and (backoffMs * 2 ** retry) ms.
     */
    backoffMs?: number;
    /**
     * Delegate auth token, used by Rooms to allow guests to send events
     */
    delegateToken?: string;
    /**
     * Suppresses login check, used in cloud-exporter to bypass isLoggedIn
     */
    suppressLoginCheck?: boolean;
}

export function getDatasetForBuild(): string {
    switch (PlatformHelpers.buildType) {
        case PlatformHelpers.BuildType.release:
            return 'app-events';
        case PlatformHelpers.BuildType.qa:
        case PlatformHelpers.BuildType.staging:
        case PlatformHelpers.BuildType.testRunner:
            return 'app-events-staging';
        default:
            return 'app-events-dev';
    }
}

/**
 * InMemoryTransmission: buffers events in memory and sends them in batches to the API endpoint
 *
 * TODO: detect network connection changes and only fire uploads when connected.
 */
export class InMemoryTransmission implements ITransmission {
    private delegateToken: string | undefined;
    private static sharedInstances: Map<string, InMemoryTransmission> = new Map();
    public static getShared(
        opts: InMemoryTransmissionOpts = { dataset: getDatasetForBuild() },
    ): InMemoryTransmission {
        const sortedKeys = Object.keys(opts).sort();
        const key = JSON.stringify(opts, sortedKeys);
        const instance = this.sharedInstances.get(key) || new InMemoryTransmission(opts);
        this.sharedInstances.set(key, instance);
        return instance;
    }

    private constructor(opts: InMemoryTransmissionOpts) {
        this.uploadSizeTrigger = opts.uploadSizeTrigger || 50;
        this.batchTimeWindow = opts.batchTimeWindow || 200;
        this.maxConcurrentBatches = opts.maxConcurrentBatches || 10;
        this.maxPendingUploads = opts.maxPendingUploads || 10000;
        this.dataset = opts.dataset;
        this.backoffMs = opts.backoffMs || 1000;
        this.maxBatchRetry = opts.maxBatchRetry || 3;
        this.delegateToken = opts.delegateToken;
        this.suppressLoginCheck = opts.suppressLoginCheck || false;

        // See comment about `maxConcurrentBatches` above
        this.batchCount = 0;
        this.eventsQueue = [];
        this.timeoutId = undefined;
        this.retryCount = 0;

        // This gets passed as a callback
        this.sendBatch = this.sendBatch.bind(this);
    }

    /**
     * Enqueue an event to be sent asynchronously later
     */
    public sendEvent(event: TraceEvent): void {
        if (this.eventsQueue.length < this.maxPendingUploads) {
            this.eventsQueue.push(event);
            this.scheduleSend();
        } else {
            console.warn('Queue overflow');
        }
    }

    /**
     * Immediately send current accumulated events to server
     */
    public async flush(): Promise<void> {
        await this.sendBatch();
    }

    // This is the non-async parent of the async functions
    private scheduleSend(): void {
        if (this.retryCount === 0 && this.eventsQueue.length >= this.uploadSizeTrigger) {
            void this.sendBatch();
        } else {
            this.ensureTimeout();
        }
    }

    private ensureTimeout() {
        if (this.timeoutId === undefined) {
            this.timeoutId = global.setTimeout(
                this.sendBatch,
                // Exponential backoff with full jitter
                this.batchTimeWindow +
                    Math.random() * this.backoffMs * Math.pow(2, this.retryCount),
            ) as unknown as TimeoutId;
        }
    }

    private ensureClearTimeout() {
        if (this.timeoutId !== undefined) {
            global.clearTimeout(this.timeoutId);
            this.timeoutId = undefined;
        }
    }

    private async sendBatch(): Promise<void> {
        // Do this first!
        this.ensureClearTimeout();
        // See comment about `maxConcurrentBatches` above
        if (this.batchCount >= this.maxConcurrentBatches) {
            // Other completing batches will scheduleSend()
            return;
        }
        if (this.eventsQueue.length === 0) {
            return;
        }
        if (!this.suppressLoginCheck && !ApiClient.isLoggedIn() && !this.delegateToken) {
            // Do this instead of scheduleSend() to prevent
            // recursive looping.
            this.ensureTimeout();
            return;
        }
        this.batchCount += 1;
        const batch = this.eventsQueue;
        try {
            this.eventsQueue = [];
            await this.uploadBatch(batch);
            this.retryCount = 0;
        } catch (e) {
            console.warn(e);
            if (this.retryCount < this.maxBatchRetry) {
                this.retryCount += 1;
                // Put these back in the front of the queue to try again
                this.eventsQueue = batch.concat(this.eventsQueue);
            } else {
                // Drop events
                this.retryCount = 0;
                console.warn(e);
            }
        } finally {
            this.batchCount -= 1;
            this.scheduleSend();
        }
    }

    private async uploadBatch(events: TraceEvent[]): Promise<void> {
        if (!this.suppressLoginCheck && !ApiClient.isLoggedIn() && !this.delegateToken) {
            throw Errors.networkError(
                new DescriptError('Not logged in', ErrorCategory.AppArchitecture),
            );
        }
        const data: UploadEventsBatchRequest = {
            client_sent_timestamp: new Date().toISOString(),
            events,
        };
        await ApiClient.request({
            // Prevent a logical "infinite loop" when ApiClient.request is traced
            ctx: defaultCtx(),
            method: ApiClient.RequestType.POST,
            path: `/logging/events_batch/${this.delegateToken && !this.suppressLoginCheck ? 'guest/' : ''}${this.dataset}`,
            data,
            fetchOpts: {
                keepalive: true,
            },
            delegateAuth: this.delegateToken,
        });
    }

    private dataset: string;
    private uploadSizeTrigger: number;
    private batchTimeWindow: number;
    private maxConcurrentBatches: number;
    private maxPendingUploads: number;
    private maxBatchRetry: number;
    private backoffMs: number;

    private eventsQueue: TraceEvent[];
    private batchCount: number;
    private timeoutId: TimeoutId | undefined;
    private retryCount: number;

    private suppressLoginCheck: boolean;
}
