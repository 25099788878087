// Copyright 2019 Descript, Inc

import {
    AssetJson,
    AssetQualityType,
    AssetUploadUser,
    BaseAssetJson,
    EncodedArtifactMetadata,
    EncodedAssetMetadata,
    MEDIA_ARTIFACT_TYPES,
    MEDIA_ASSET_TYPES,
    MediaLibraryArtifact,
    MediaLibraryAsset,
} from '../Schemas/AssetInterfaces';
import * as TypeChecking from '../Utilities/TypeChecking';
import { Draft, produce } from 'immer';
import { fastDeepEqual } from '@descript/fast-deep-equal';
import { keys } from '@descript/descript-core';

export const qualityNames: AssetQualityType[] = ['preview', 'original'];
export const defaultName = 'Untitled';

export function getDefaultAsset(
    rootAsset: AssetJson,
    quality?: AssetQualityType,
): BaseAssetJson {
    if (quality) {
        return getQualityAsset(rootAsset, quality, 'preview', 'original');
    }
    return getQualityAsset(rootAsset, 'preview', 'original');
}

export function getQualityAsset(
    rootAsset: AssetJson,
    ...qualities: AssetQualityType[]
): BaseAssetJson {
    if (rootAsset.quality && qualities.length) {
        for (const quality of qualities) {
            const asset = rootAsset.quality[quality];
            if (asset) {
                return asset;
            }
        }
    }
    return rootAsset;
}

/**
 * Update the BaseAssetJson for a given quality in a full AssetJson.
 *
 * This does not update anything if the urls differ.
 *
 * @param rootAsset
 * @param quality
 * @param asset
 */
export function updateQuality(
    rootAsset: AssetJson,
    quality: AssetQualityType | undefined,
    asset: BaseAssetJson,
): AssetJson {
    return produce(rootAsset, (draft) => {
        updateQualityOnDraft(draft, quality, asset);
    });
}

export function updateQualityOnDraft(
    draft: Draft<AssetJson>,
    quality: AssetQualityType | undefined,
    asset: BaseAssetJson,
): void {
    if (quality === undefined) {
        if (isMatchingBaseAssetJson(draft, asset)) {
            // Don't use Object.assign or Object.entries because they play poorly with immer.produce
            for (const key of keys(asset)) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if ((key as any) === 'quality') {
                    continue;
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if ((key as any) === 'local_id' && (asset as AssetJson).guid) {
                    continue;
                }
                if (key === 'artifact_local_id' && asset.artifact_guid) {
                    continue;
                }
                if (fastDeepEqual(asset[key], draft[key])) {
                    continue;
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (draft as any)[key] = asset[key];
            }
            if (draft.pending_upload_by !== undefined) {
                delete draft.pending_upload_by;
            }
            if (draft.local_id && draft.guid) {
                delete draft.local_id;
            }
            if (draft.artifact_local_id && draft.artifact_guid) {
                delete draft.artifact_local_id;
            }
        }
    } else if (!draft.quality) {
        draft.quality = { [quality]: asset };
    } else {
        const qualityElement = draft.quality[quality];
        if (!qualityElement) {
            draft.quality[quality] = asset;
        } else if (isMatchingBaseAssetJson(qualityElement, asset)) {
            for (const key of keys(asset)) {
                if (key === 'artifact_local_id' && asset.artifact_guid) {
                    continue;
                }
                if (fastDeepEqual(asset[key], qualityElement[key])) {
                    continue;
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (qualityElement as any)[key] = asset[key];
            }
            if (qualityElement.pending_upload_by !== undefined) {
                delete qualityElement.pending_upload_by;
            }
            if (qualityElement.artifact_local_id && qualityElement.artifact_guid) {
                delete qualityElement.artifact_local_id;
            }
        }
    }
}

function isMatchingBaseAssetJson(a: BaseAssetJson, b: BaseAssetJson): boolean {
    return !!(
        a.url === b.url ||
        (a.artifact_guid && a.artifact_guid === b.artifact_guid) ||
        (a.artifact_local_id && b.artifact_local_id === b.artifact_local_id)
    );
}

export function hasMatchingMd5(a: AssetJson, b: AssetJson): boolean {
    if (a.md5 !== undefined && a.md5 === b.md5) {
        return true;
    }

    if (!a.quality || !b.quality) {
        return false;
    }

    if (
        a.quality.preview &&
        b.quality.preview &&
        a.quality.preview.md5 !== undefined &&
        a.quality.preview.md5 === b.quality.preview.md5
    ) {
        return true;
    }

    if (
        a.quality.original &&
        b.quality.original &&
        a.quality.original.md5 !== undefined &&
        a.quality.original.md5 === b.quality.original.md5
    ) {
        return true;
    }

    return false;
}

export function getPendingUpload({
    quality,
    pending_upload_by,
}: AssetJson): AssetUploadUser | undefined {
    if (pending_upload_by) {
        return pending_upload_by;
    }
    if (quality) {
        for (const qualityName of qualityNames) {
            const asset = quality[qualityName];
            if (asset && asset.pending_upload_by) {
                return asset.pending_upload_by;
            }
        }
    }
    return undefined;
}

/**
 * The original file URL is used as the lookup key for legacy assets
 * in the global media asset system.
 */
export function getOriginalFileUrl(assetJson: AssetJson): string {
    return assetJson?.quality?.original?.url ?? assetJson.url;
}

export function encodeAssetMetadata(
    asset: Pick<MediaLibraryAsset, 'defaultDisplayName' | 'assetType' | 'metadata'>,
): EncodedAssetMetadata {
    return {
        default_display_name: asset.defaultDisplayName,
        type: asset.assetType,
        ...(asset.metadata ?? {}),
    };
}

export function decodeAssetMetadata(
    metadata: EncodedAssetMetadata,
): Pick<MediaLibraryAsset, 'defaultDisplayName' | 'assetType' | 'metadata'> {
    const { default_display_name, type, ...rest } = metadata;

    return {
        defaultDisplayName:
            typeof default_display_name === 'string' ? default_display_name : 'unknown',
        assetType: TypeChecking.ensureIsOneOf(type, MEDIA_ASSET_TYPES, 'unknown'),
        metadata: rest,
    };
}

export function encodeArtifactMetadata(
    artifact: Pick<MediaLibraryArtifact, 'displayName' | 'type' | 'metadata'>,
): EncodedArtifactMetadata {
    return {
        filename: artifact.displayName,
        type: artifact.type,
        ...(artifact.metadata ?? {}),
    };
}

export function decodeArtifactMetadata(
    metadata: EncodedArtifactMetadata,
): Pick<MediaLibraryArtifact, 'displayName' | 'type' | 'metadata'> {
    const { filename, type, ...rest } = metadata;

    // Server-side generated artifacts had a bug where
    // type and filename weren't being encoded (BCK-609)
    // These were mainly AI speech/roomtone/etc. that don't
    // have proxy assets
    return {
        displayName: typeof filename === 'string' ? filename : 'unknown',
        type: TypeChecking.ensureIsOneOf(type, MEDIA_ARTIFACT_TYPES, 'original'),
        metadata: rest,
    };
}

/**
 * The displayName of an asset cannot be the empty string
 */
export function ensureValidDisplayName(displayName: string): string {
    return displayName === '' ? defaultName : displayName;
}
