// Copyright 2025 Descript, Inc
import { useLocalSessionId, useMediaTrack } from '@daily-co/daily-react';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { atom, useRecoilState } from 'recoil';

const COOKIE_KEY = 'auto-gain-control-enabled';

const autoGainControlState = atom<boolean>({
    key: COOKIE_KEY,
    default: true,
    effects: [
        /**
         * Synchronizes atom state with cookie.
         */
        ({ onSet, setSelf, trigger }) => {
            if (trigger === 'get') {
                const storedEchoCancellationEnabled = Cookies.get(COOKIE_KEY);
                if (storedEchoCancellationEnabled) {
                    setSelf(
                        storedEchoCancellationEnabled === 'true'
                            ? true
                            : storedEchoCancellationEnabled === 'false'
                              ? false
                              : false,
                    );
                }
            }
            onSet((newEchoCancellationEnabled) => {
                Cookies.set(COOKIE_KEY, newEchoCancellationEnabled ? 'true' : 'false', {
                    expires: new Date('Tue, 19 Jan 2038 03:14:07 GMT'),
                    sameSite: 'none',
                    secure: true,
                });
            });
        },
    ],
});

export const useAutoGainControlEnabled = () => useRecoilState(autoGainControlState);

export const useAutoGainControl = () => {
    const [autoGainControlEnabled] = useAutoGainControlEnabled();
    const localSessionId = useLocalSessionId();
    const mediaTrack = useMediaTrack(localSessionId, 'audio');

    useEffect(() => {
        if (!mediaTrack.track) {
            return;
        }

        const constraints = mediaTrack.track.getConstraints() ?? {
            autoGainControl: false,
        };

        if (autoGainControlEnabled !== constraints.autoGainControl) {
            mediaTrack.track
                .applyConstraints({
                    ...constraints,
                    autoGainControl: autoGainControlEnabled,
                })
                .catch(() => console.error('Failed to set auto gain control'));
        }
    }, [mediaTrack.track, autoGainControlEnabled]);
};
