// Copyright 2023 Descript, Inc
/// <reference types="@typescript/lib-dom" />

/**
 * @descript/web-recorder-recovery
 *
 * client-side primitives for interacting with web recorder recovery that is connected to the device our app is running on
 *
 */

export * from './recovery/WebRecorderRecovery';
